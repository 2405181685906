@charset 'UTF-8';

meta.foundation-version {
  font-family: '/5.2.3/';
}

meta.foundation-mq-small {
  font-family: '/only screen/';
  width: 0;
}

meta.foundation-mq-medium {
  font-family: '/only screen and (min-width:40.063em)/';
  width: 40.063em;
}

meta.foundation-mq-large {
  font-family: '/only screen and (min-width:64.063em)/';
  width: 64.063em;
}

meta.foundation-mq-xlarge {
  font-family: '/only screen and (min-width:90.063em)/';
  width: 90.063em;
}

meta.foundation-mq-xxlarge {
  font-family: '/only screen and (min-width:120.063em)/';
  width: 120.063em;
}

meta.foundation-data-attribute-namespace {
  font-family: false;
}

html,
body {
  height: 100%;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  font-size: 100%;
}

body {
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  position: relative;
  margin: 0;
  padding: 0;
  cursor: default;
  color: #222;
  background: white;
}

a:hover {
  cursor: pointer;
}

img {
  max-width: 100%;
  height: auto;
}

img {
  -ms-interpolation-mode: bicubic;
}

#map_canvas img,
#map_canvas embed,
#map_canvas object,
.map_canvas img,
.map_canvas embed,
.map_canvas object {
  max-width: none !important;
}

.left {
  float: left !important;
}

.right {
  float: right !important;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: ' ';
}

.clearfix:after {
  clear: both;
}

.hide {
  display: none;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  display: inline-block;
  vertical-align: middle;
}

textarea {
  height: auto;
  min-height: 50px;
}

select {
  width: 100%;
}

.row {
  width: 100%;
  max-width: 75em;
  margin-top: 0;
  margin-right: auto;
  margin-bottom: 0;
  margin-left: auto;
}

.row:before,
.row:after {
  display: table;
  content: ' ';
}

.row:after {
  clear: both;
}

.row.collapse > .column,
.row.collapse > .columns {
  padding-right: 0;
  padding-left: 0;
}

.row.collapse .row {
  margin-right: 0;
  margin-left: 0;
}

.row .row {
  width: auto;
  max-width: none;
  margin-top: 0;
  margin-right: -.9375em;
  margin-bottom: 0;
  margin-left: -.9375em;
}

.row .row:before,
.row .row:after {
  display: table;
  content: ' ';
}

.row .row:after {
  clear: both;
}

.row .row.collapse {
  width: auto;
  max-width: none;
  margin: 0;
}

.row .row.collapse:before,
.row .row.collapse:after {
  display: table;
  content: ' ';
}

.row .row.collapse:after {
  clear: both;
}

.column,
.columns {
  float: left;
  width: 100%;
  padding-right: .9375em;
  padding-left: .9375em;
}

[class*='column'] + [class*='column']:last-child {
  float: right;
}

[class*='column'] + [class*='column'].end {
  float: left;
}

@media only screen {
  .small-push-0 {
    position: relative;
    right: auto;
    left: 0;
  }

  .small-pull-0 {
    position: relative;
    right: 0;
    left: auto;
  }

  .small-push-1 {
    position: relative;
    right: auto;
    left: 8.33333%;
  }

  .small-pull-1 {
    position: relative;
    right: 8.33333%;
    left: auto;
  }

  .small-push-2 {
    position: relative;
    right: auto;
    left: 16.66667%;
  }

  .small-pull-2 {
    position: relative;
    right: 16.66667%;
    left: auto;
  }

  .small-push-3 {
    position: relative;
    right: auto;
    left: 25%;
  }

  .small-pull-3 {
    position: relative;
    right: 25%;
    left: auto;
  }

  .small-push-4 {
    position: relative;
    right: auto;
    left: 33.33333%;
  }

  .small-pull-4 {
    position: relative;
    right: 33.33333%;
    left: auto;
  }

  .small-push-5 {
    position: relative;
    right: auto;
    left: 41.66667%;
  }

  .small-pull-5 {
    position: relative;
    right: 41.66667%;
    left: auto;
  }

  .small-push-6 {
    position: relative;
    right: auto;
    left: 50%;
  }

  .small-pull-6 {
    position: relative;
    right: 50%;
    left: auto;
  }

  .small-push-7 {
    position: relative;
    right: auto;
    left: 58.33333%;
  }

  .small-pull-7 {
    position: relative;
    right: 58.33333%;
    left: auto;
  }

  .small-push-8 {
    position: relative;
    right: auto;
    left: 66.66667%;
  }

  .small-pull-8 {
    position: relative;
    right: 66.66667%;
    left: auto;
  }

  .small-push-9 {
    position: relative;
    right: auto;
    left: 75%;
  }

  .small-pull-9 {
    position: relative;
    right: 75%;
    left: auto;
  }

  .small-push-10 {
    position: relative;
    right: auto;
    left: 83.33333%;
  }

  .small-pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto;
  }

  .small-push-11 {
    position: relative;
    right: auto;
    left: 91.66667%;
  }

  .small-pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto;
  }

  .column,
    .columns {
    position: relative;
    float: left;
    padding-right: .9375em;
    padding-left: .9375em;
  }

  .small-1 {
    width: 8.33333%;
  }

  .small-2 {
    width: 16.66667%;
  }

  .small-3 {
    width: 25%;
  }

  .small-4 {
    width: 33.33333%;
  }

  .small-5 {
    width: 41.66667%;
  }

  .small-6 {
    width: 50%;
  }

  .small-7 {
    width: 58.33333%;
  }

  .small-8 {
    width: 66.66667%;
  }

  .small-9 {
    width: 75%;
  }

  .small-10 {
    width: 83.33333%;
  }

  .small-11 {
    width: 91.66667%;
  }

  .small-12 {
    width: 100%;
  }

  .small-offset-0 {
    margin-left: 0 !important;
  }

  .small-offset-1 {
    margin-left: 8.33333% !important;
  }

  .small-offset-2 {
    margin-left: 16.66667% !important;
  }

  .small-offset-3 {
    margin-left: 25% !important;
  }

  .small-offset-4 {
    margin-left: 33.33333% !important;
  }

  .small-offset-5 {
    margin-left: 41.66667% !important;
  }

  .small-offset-6 {
    margin-left: 50% !important;
  }

  .small-offset-7 {
    margin-left: 58.33333% !important;
  }

  .small-offset-8 {
    margin-left: 66.66667% !important;
  }

  .small-offset-9 {
    margin-left: 75% !important;
  }

  .small-offset-10 {
    margin-left: 83.33333% !important;
  }

  .small-offset-11 {
    margin-left: 91.66667% !important;
  }

  .small-reset-order {
    right: auto;
    left: auto;
    float: left;
    margin-right: 0;
    margin-left: 0;
  }

  .column.small-centered,
    .columns.small-centered {
    float: none;
    margin-right: auto;
    margin-left: auto;
  }

  .column.small-uncentered,
    .columns.small-uncentered {
    float: left;
    margin-right: 0;
    margin-left: 0;
  }

  .column.small-centered:last-child,
    .columns.small-centered:last-child {
    float: none;
  }

  .column.small-uncentered:last-child,
    .columns.small-uncentered:last-child {
    float: left;
  }

  .column.small-uncentered.opposite,
    .columns.small-uncentered.opposite {
    float: right;
  }
}

@media only screen and (min-width: 40.063em) {
  .medium-push-0 {
    position: relative;
    right: auto;
    left: 0;
  }

  .medium-pull-0 {
    position: relative;
    right: 0;
    left: auto;
  }

  .medium-push-1 {
    position: relative;
    right: auto;
    left: 8.33333%;
  }

  .medium-pull-1 {
    position: relative;
    right: 8.33333%;
    left: auto;
  }

  .medium-push-2 {
    position: relative;
    right: auto;
    left: 16.66667%;
  }

  .medium-pull-2 {
    position: relative;
    right: 16.66667%;
    left: auto;
  }

  .medium-push-3 {
    position: relative;
    right: auto;
    left: 25%;
  }

  .medium-pull-3 {
    position: relative;
    right: 25%;
    left: auto;
  }

  .medium-push-4 {
    position: relative;
    right: auto;
    left: 33.33333%;
  }

  .medium-pull-4 {
    position: relative;
    right: 33.33333%;
    left: auto;
  }

  .medium-push-5 {
    position: relative;
    right: auto;
    left: 41.66667%;
  }

  .medium-pull-5 {
    position: relative;
    right: 41.66667%;
    left: auto;
  }

  .medium-push-6 {
    position: relative;
    right: auto;
    left: 50%;
  }

  .medium-pull-6 {
    position: relative;
    right: 50%;
    left: auto;
  }

  .medium-push-7 {
    position: relative;
    right: auto;
    left: 58.33333%;
  }

  .medium-pull-7 {
    position: relative;
    right: 58.33333%;
    left: auto;
  }

  .medium-push-8 {
    position: relative;
    right: auto;
    left: 66.66667%;
  }

  .medium-pull-8 {
    position: relative;
    right: 66.66667%;
    left: auto;
  }

  .medium-push-9 {
    position: relative;
    right: auto;
    left: 75%;
  }

  .medium-pull-9 {
    position: relative;
    right: 75%;
    left: auto;
  }

  .medium-push-10 {
    position: relative;
    right: auto;
    left: 83.33333%;
  }

  .medium-pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto;
  }

  .medium-push-11 {
    position: relative;
    right: auto;
    left: 91.66667%;
  }

  .medium-pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto;
  }

  .column,
    .columns {
    position: relative;
    float: left;
    padding-right: .9375em;
    padding-left: .9375em;
  }

  .medium-1 {
    width: 8.33333%;
  }

  .medium-2 {
    width: 16.66667%;
  }

  .medium-3 {
    width: 25%;
  }

  .medium-4 {
    width: 33.33333%;
  }

  .medium-5 {
    width: 41.66667%;
  }

  .medium-6 {
    width: 50%;
  }

  .medium-7 {
    width: 58.33333%;
  }

  .medium-8 {
    width: 66.66667%;
  }

  .medium-9 {
    width: 75%;
  }

  .medium-10 {
    width: 83.33333%;
  }

  .medium-11 {
    width: 91.66667%;
  }

  .medium-12 {
    width: 100%;
  }

  .medium-offset-0 {
    margin-left: 0 !important;
  }

  .medium-offset-1 {
    margin-left: 8.33333% !important;
  }

  .medium-offset-2 {
    margin-left: 16.66667% !important;
  }

  .medium-offset-3 {
    margin-left: 25% !important;
  }

  .medium-offset-4 {
    margin-left: 33.33333% !important;
  }

  .medium-offset-5 {
    margin-left: 41.66667% !important;
  }

  .medium-offset-6 {
    margin-left: 50% !important;
  }

  .medium-offset-7 {
    margin-left: 58.33333% !important;
  }

  .medium-offset-8 {
    margin-left: 66.66667% !important;
  }

  .medium-offset-9 {
    margin-left: 75% !important;
  }

  .medium-offset-10 {
    margin-left: 83.33333% !important;
  }

  .medium-offset-11 {
    margin-left: 91.66667% !important;
  }

  .medium-reset-order {
    right: auto;
    left: auto;
    float: left;
    margin-right: 0;
    margin-left: 0;
  }

  .column.medium-centered,
    .columns.medium-centered {
    float: none;
    margin-right: auto;
    margin-left: auto;
  }

  .column.medium-uncentered,
    .columns.medium-uncentered {
    float: left;
    margin-right: 0;
    margin-left: 0;
  }

  .column.medium-centered:last-child,
    .columns.medium-centered:last-child {
    float: none;
  }

  .column.medium-uncentered:last-child,
    .columns.medium-uncentered:last-child {
    float: left;
  }

  .column.medium-uncentered.opposite,
    .columns.medium-uncentered.opposite {
    float: right;
  }

  .push-0 {
    position: relative;
    right: auto;
    left: 0;
  }

  .pull-0 {
    position: relative;
    right: 0;
    left: auto;
  }

  .push-1 {
    position: relative;
    right: auto;
    left: 8.33333%;
  }

  .pull-1 {
    position: relative;
    right: 8.33333%;
    left: auto;
  }

  .push-2 {
    position: relative;
    right: auto;
    left: 16.66667%;
  }

  .pull-2 {
    position: relative;
    right: 16.66667%;
    left: auto;
  }

  .push-3 {
    position: relative;
    right: auto;
    left: 25%;
  }

  .pull-3 {
    position: relative;
    right: 25%;
    left: auto;
  }

  .push-4 {
    position: relative;
    right: auto;
    left: 33.33333%;
  }

  .pull-4 {
    position: relative;
    right: 33.33333%;
    left: auto;
  }

  .push-5 {
    position: relative;
    right: auto;
    left: 41.66667%;
  }

  .pull-5 {
    position: relative;
    right: 41.66667%;
    left: auto;
  }

  .push-6 {
    position: relative;
    right: auto;
    left: 50%;
  }

  .pull-6 {
    position: relative;
    right: 50%;
    left: auto;
  }

  .push-7 {
    position: relative;
    right: auto;
    left: 58.33333%;
  }

  .pull-7 {
    position: relative;
    right: 58.33333%;
    left: auto;
  }

  .push-8 {
    position: relative;
    right: auto;
    left: 66.66667%;
  }

  .pull-8 {
    position: relative;
    right: 66.66667%;
    left: auto;
  }

  .push-9 {
    position: relative;
    right: auto;
    left: 75%;
  }

  .pull-9 {
    position: relative;
    right: 75%;
    left: auto;
  }

  .push-10 {
    position: relative;
    right: auto;
    left: 83.33333%;
  }

  .pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto;
  }

  .push-11 {
    position: relative;
    right: auto;
    left: 91.66667%;
  }

  .pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto;
  }
}

@media only screen and (min-width: 64.063em) {
  .large-push-0 {
    position: relative;
    right: auto;
    left: 0;
  }

  .large-pull-0 {
    position: relative;
    right: 0;
    left: auto;
  }

  .large-push-1 {
    position: relative;
    right: auto;
    left: 8.33333%;
  }

  .large-pull-1 {
    position: relative;
    right: 8.33333%;
    left: auto;
  }

  .large-push-2 {
    position: relative;
    right: auto;
    left: 16.66667%;
  }

  .large-pull-2 {
    position: relative;
    right: 16.66667%;
    left: auto;
  }

  .large-push-3 {
    position: relative;
    right: auto;
    left: 25%;
  }

  .large-pull-3 {
    position: relative;
    right: 25%;
    left: auto;
  }

  .large-push-4 {
    position: relative;
    right: auto;
    left: 33.33333%;
  }

  .large-pull-4 {
    position: relative;
    right: 33.33333%;
    left: auto;
  }

  .large-push-5 {
    position: relative;
    right: auto;
    left: 41.66667%;
  }

  .large-pull-5 {
    position: relative;
    right: 41.66667%;
    left: auto;
  }

  .large-push-6 {
    position: relative;
    right: auto;
    left: 50%;
  }

  .large-pull-6 {
    position: relative;
    right: 50%;
    left: auto;
  }

  .large-push-7 {
    position: relative;
    right: auto;
    left: 58.33333%;
  }

  .large-pull-7 {
    position: relative;
    right: 58.33333%;
    left: auto;
  }

  .large-push-8 {
    position: relative;
    right: auto;
    left: 66.66667%;
  }

  .large-pull-8 {
    position: relative;
    right: 66.66667%;
    left: auto;
  }

  .large-push-9 {
    position: relative;
    right: auto;
    left: 75%;
  }

  .large-pull-9 {
    position: relative;
    right: 75%;
    left: auto;
  }

  .large-push-10 {
    position: relative;
    right: auto;
    left: 83.33333%;
  }

  .large-pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto;
  }

  .large-push-11 {
    position: relative;
    right: auto;
    left: 91.66667%;
  }

  .large-pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto;
  }

  .column,
    .columns {
    position: relative;
    float: left;
    padding-right: .9375em;
    padding-left: .9375em;
  }

  .large-1 {
    width: 8.33333%;
  }

  .large-2 {
    width: 16.66667%;
  }

  .large-3 {
    width: 25%;
  }

  .large-4 {
    width: 33.33333%;
  }

  .large-5 {
    width: 41.66667%;
  }

  .large-6 {
    width: 50%;
  }

  .large-7 {
    width: 58.33333%;
  }

  .large-8 {
    width: 66.66667%;
  }

  .large-9 {
    width: 75%;
  }

  .large-10 {
    width: 83.33333%;
  }

  .large-11 {
    width: 91.66667%;
  }

  .large-12 {
    width: 100%;
  }

  .large-offset-0 {
    margin-left: 0 !important;
  }

  .large-offset-1 {
    margin-left: 8.33333% !important;
  }

  .large-offset-2 {
    margin-left: 16.66667% !important;
  }

  .large-offset-3 {
    margin-left: 25% !important;
  }

  .large-offset-4 {
    margin-left: 33.33333% !important;
  }

  .large-offset-5 {
    margin-left: 41.66667% !important;
  }

  .large-offset-6 {
    margin-left: 50% !important;
  }

  .large-offset-7 {
    margin-left: 58.33333% !important;
  }

  .large-offset-8 {
    margin-left: 66.66667% !important;
  }

  .large-offset-9 {
    margin-left: 75% !important;
  }

  .large-offset-10 {
    margin-left: 83.33333% !important;
  }

  .large-offset-11 {
    margin-left: 91.66667% !important;
  }

  .large-reset-order {
    right: auto;
    left: auto;
    float: left;
    margin-right: 0;
    margin-left: 0;
  }

  .column.large-centered,
    .columns.large-centered {
    float: none;
    margin-right: auto;
    margin-left: auto;
  }

  .column.large-uncentered,
    .columns.large-uncentered {
    float: left;
    margin-right: 0;
    margin-left: 0;
  }

  .column.large-centered:last-child,
    .columns.large-centered:last-child {
    float: none;
  }

  .column.large-uncentered:last-child,
    .columns.large-uncentered:last-child {
    float: left;
  }

  .column.large-uncentered.opposite,
    .columns.large-uncentered.opposite {
    float: right;
  }

  .push-0 {
    position: relative;
    right: auto;
    left: 0;
  }

  .pull-0 {
    position: relative;
    right: 0;
    left: auto;
  }

  .push-1 {
    position: relative;
    right: auto;
    left: 8.33333%;
  }

  .pull-1 {
    position: relative;
    right: 8.33333%;
    left: auto;
  }

  .push-2 {
    position: relative;
    right: auto;
    left: 16.66667%;
  }

  .pull-2 {
    position: relative;
    right: 16.66667%;
    left: auto;
  }

  .push-3 {
    position: relative;
    right: auto;
    left: 25%;
  }

  .pull-3 {
    position: relative;
    right: 25%;
    left: auto;
  }

  .push-4 {
    position: relative;
    right: auto;
    left: 33.33333%;
  }

  .pull-4 {
    position: relative;
    right: 33.33333%;
    left: auto;
  }

  .push-5 {
    position: relative;
    right: auto;
    left: 41.66667%;
  }

  .pull-5 {
    position: relative;
    right: 41.66667%;
    left: auto;
  }

  .push-6 {
    position: relative;
    right: auto;
    left: 50%;
  }

  .pull-6 {
    position: relative;
    right: 50%;
    left: auto;
  }

  .push-7 {
    position: relative;
    right: auto;
    left: 58.33333%;
  }

  .pull-7 {
    position: relative;
    right: 58.33333%;
    left: auto;
  }

  .push-8 {
    position: relative;
    right: auto;
    left: 66.66667%;
  }

  .pull-8 {
    position: relative;
    right: 66.66667%;
    left: auto;
  }

  .push-9 {
    position: relative;
    right: auto;
    left: 75%;
  }

  .pull-9 {
    position: relative;
    right: 75%;
    left: auto;
  }

  .push-10 {
    position: relative;
    right: auto;
    left: 83.33333%;
  }

  .pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto;
  }

  .push-11 {
    position: relative;
    right: auto;
    left: 91.66667%;
  }

  .pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto;
  }
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-justify {
  text-align: justify !important;
}

@media only screen and (max-width: 40em) {
  .small-only-text-left {
    text-align: left !important;
  }

  .small-only-text-right {
    text-align: right !important;
  }

  .small-only-text-center {
    text-align: center !important;
  }

  .small-only-text-justify {
    text-align: justify !important;
  }
}

@media only screen {
  .small-text-left {
    text-align: left !important;
  }

  .small-text-right {
    text-align: right !important;
  }

  .small-text-center {
    text-align: center !important;
  }

  .small-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (min-width: 40.063em) and (max-width: 64em) {
  .medium-only-text-left {
    text-align: left !important;
  }

  .medium-only-text-right {
    text-align: right !important;
  }

  .medium-only-text-center {
    text-align: center !important;
  }

  .medium-only-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (min-width: 40.063em) {
  .medium-text-left {
    text-align: left !important;
  }

  .medium-text-right {
    text-align: right !important;
  }

  .medium-text-center {
    text-align: center !important;
  }

  .medium-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (min-width: 64.063em) and (max-width: 90em) {
  .large-only-text-left {
    text-align: left !important;
  }

  .large-only-text-right {
    text-align: right !important;
  }

  .large-only-text-center {
    text-align: center !important;
  }

  .large-only-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (min-width: 64.063em) {
  .large-text-left {
    text-align: left !important;
  }

  .large-text-right {
    text-align: right !important;
  }

  .large-text-center {
    text-align: center !important;
  }

  .large-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (min-width: 90.063em) and (max-width: 120em) {
  .xlarge-only-text-left {
    text-align: left !important;
  }

  .xlarge-only-text-right {
    text-align: right !important;
  }

  .xlarge-only-text-center {
    text-align: center !important;
  }

  .xlarge-only-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (min-width: 90.063em) {
  .xlarge-text-left {
    text-align: left !important;
  }

  .xlarge-text-right {
    text-align: right !important;
  }

  .xlarge-text-center {
    text-align: center !important;
  }

  .xlarge-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (min-width: 120.063em) and (max-width: 99999999em) {
  .xxlarge-only-text-left {
    text-align: left !important;
  }

  .xxlarge-only-text-right {
    text-align: right !important;
  }

  .xxlarge-only-text-center {
    text-align: center !important;
  }

  .xxlarge-only-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (min-width: 120.063em) {
  .xxlarge-text-left {
    text-align: left !important;
  }

  .xxlarge-text-right {
    text-align: right !important;
  }

  .xxlarge-text-center {
    text-align: center !important;
  }

  .xxlarge-text-justify {
    text-align: justify !important;
  }
}

/* Typography resets */
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

/* Default Link Styles */
a {
  line-height: inherit;
  text-decoration: none;
  color: #2ba6cb;
}

a:hover,
a:focus {
  color: #258faf;
}

a img {
  border: none;
}

/* Default paragraph styles */
p {
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.6;
  margin-bottom: 1.25rem;
  text-rendering: optimizeLegibility;
}

p.lead {
  font-size: 1.21875rem;
  line-height: 1.6;
}

p aside {
  font-size: .875rem;
  font-style: italic;
  line-height: 1.35;
}

/* Default header styles */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  line-height: 1.4;
  margin-top: .2rem;
  margin-bottom: .5rem;
  color: #222;
  text-rendering: optimizeLegibility;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-size: 60%;
  line-height: 0;
  color: #6f6f6f;
}

h1 {
  font-size: 2.125rem;
}

h2 {
  font-size: 1.6875rem;
}

h3 {
  font-size: 1.375rem;
}

h4 {
  font-size: 1.125rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

.subheader {
  font-weight: normal;
  line-height: 1.4;
  margin-top: .2rem;
  margin-bottom: .5rem;
  color: #6f6f6f;
}

hr {
  clear: both;
  height: 0;
  margin: 1.25rem 0 1.1875rem;
  border: solid #ddd;
  border-width: 1px 0 0;
}

/* Helpful Typography Defaults */
em,
i {
  font-style: italic;
  line-height: inherit;
}

strong,
b {
  font-weight: bold;
  line-height: inherit;
}

small {
  font-size: 60%;
  line-height: inherit;
}

code {
  font-family: Consolas, 'Liberation Mono', Courier, monospace;
  font-weight: bold;
  color: #910b0e;
}

/* Lists */
ul,
ol,
dl {
  font-family: inherit;
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1.25rem;
  list-style-position: outside;
}

ul {
  margin-left: 1.1rem;
}

ul.no-bullet {
  margin-left: 0;
}

ul.no-bullet li ul,
ul.no-bullet li ol {
  margin-bottom: 0;
  margin-left: 1.25rem;
  list-style: none;
}

/* Unordered Lists */
ul li ul,
ul li ol {
  margin-bottom: 0;
  margin-left: 1.25rem;
}

ul.square li ul,
ul.circle li ul,
ul.disc li ul {
  list-style: inherit;
}

ul.square {
  margin-left: 1.1rem;
  list-style-type: square;
}

ul.circle {
  margin-left: 1.1rem;
  list-style-type: circle;
}

ul.disc {
  margin-left: 1.1rem;
  list-style-type: disc;
}

ul.no-bullet {
  list-style: none;
}

/* Ordered Lists */
ol {
  margin-left: 1.4rem;
}

ol li ul,
ol li ol {
  margin-bottom: 0;
  margin-left: 1.25rem;
}

/* Definition Lists */
dl dt {
  font-weight: bold;
  margin-bottom: .3rem;
}

dl dd {
  margin-bottom: .75rem;
}

/* Abbreviations */
abbr,
acronym {
  font-size: 90%;
  cursor: help;
  text-transform: uppercase;
  color: #222;
  border-bottom: 1px dotted #ddd;
}

abbr {
  text-transform: none;
}

/* Blockquotes */
blockquote {
  margin: 0 0 1.25rem;
  padding: .5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #ddd;
}

blockquote cite {
  font-size: .8125rem;
  display: block;
  color: #555;
}

blockquote cite:before {
  content: '\2014 \0020';
}

blockquote cite a,
blockquote cite a:visited {
  color: #555;
}

blockquote,
blockquote p {
  line-height: 1.6;
  color: #6f6f6f;
}

/* Microformats */
.vcard {
  display: inline-block;
  margin: 0 0 1.25rem 0;
  padding: .625rem .75rem;
  border: 1px solid #ddd;
}

.vcard li {
  display: block;
  margin: 0;
}

.vcard .fn {
  font-size: .9375rem;
  font-weight: bold;
}

.vevent .summary {
  font-weight: bold;
}

.vevent abbr {
  font-weight: bold;
  padding: 0 .0625rem;
  cursor: default;
  text-decoration: none;
  border: none;
}

@media only screen and (min-width: 40.063em) {
  h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
    line-height: 1.4;
  }

  h1 {
    font-size: 2.75rem;
  }

  h2 {
    font-size: 2.3125rem;
  }

  h3 {
    font-size: 1.6875rem;
  }

  h4 {
    font-size: 1.4375rem;
  }

  h5 {
    font-size: 1.125rem;
  }

  h6 {
    font-size: 1rem;
  }
}

/*
 * Print styles.
 *
 * Inlined to avoid required HTTP connection: www.phpied.com/delay-loading-your-print-css/
 * Credit to Paul Irish and HTML5 Boilerplate (html5boilerplate.com)
*/
.print-only {
  display: none !important;
}

@media print {
  * {
    color: black !important;
    background: transparent !important;
        /* Black prints faster: h5bp.com/s */
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
    a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: ' (' attr(href) ')';
  }

  abbr[title]:after {
    content: ' (' attr(title) ')';
  }

  .ir a:after,
    a[href^='javascript:']:after,
    a[href^='#']:after {
    content: '';
  }

  pre,
    blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
        /* h5bp.com/t */
  }

  tr,
    img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  p,
    h2,
    h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
    h3 {
    page-break-after: avoid;
  }

  .hide-on-print {
    display: none !important;
  }

  .print-only {
    display: block !important;
  }

  .hide-for-print {
    display: none !important;
  }

  .show-for-print {
    display: inherit !important;
  }
}

/*
 * jQuery FlexSlider v2.2.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 */
/* Browser Resets
*********************************/
.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus {
  outline: none;
}

.slides,
.flex-control-nav,
.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* FlexSlider Necessary Styles
*********************************/
.flexslider {
  margin: 0;
  padding: 0;
}

.flexslider .slides > li {
  display: none;
  -webkit-backface-visibility: hidden;
}

/* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides img {
  display: block;
  width: 100%;
}

.flex-pauseplay span {
  text-transform: capitalize;
}

/* Clearfix for the .slides element */
.slides:after {
  line-height: 0;
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '\0020';
}

html[xmlns] .slides {
  display: block;
}

* html .slides {
  height: 1%;
}

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */
.no-js .slides > li:first-child {
  display: block;
}

/* FlexSlider Default Theme
*********************************/
.flexslider {
  position: relative;
  zoom: 1;
  margin: 0 0 60px;
  border: 4px solid #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: #fff;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, .2);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, .2);
  box-shadow: 0 1px 4px rgba(0, 0, 0, .2);
  -o-border-radius: 4px;
  -o-box-shadow: 0 1px 4px rgba(0, 0, 0, .2);
}

.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.loading .flex-viewport {
  max-height: 300px;
}

.flexslider .slides {
  zoom: 1;
}

.carousel li {
  margin-right: 5px;
}

/* Direction Nav */
.flex-direction-nav {
  *height: 0;
}

.flex-direction-nav a {
  position: absolute;
  z-index: 10;
  top: 50%;
  display: block;
  overflow: hidden;
  width: 60px;
  height: 60px;
  margin: -20px 0 0;
  cursor: pointer;
  cursor: pointer;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  transition: all .3s ease;
  opacity: 1;
  color: rgba(0, 0, 0, .8);
  border: 2px solid #fff;
  border-radius: 50%;
  background: none;
  text-shadow: 1px 1px 0 rgba(255, 255, 255, .3);
}

.flex-direction-nav a:hover {
  background: rgba(255, 255, 255, .3);
}

.flex-direction-nav .flex-prev {
  left: 17px;
}

.flex-direction-nav .flex-next {
  right: 17px;
  text-align: right;
}

.flexslider:hover .flex-prev {
  left: 10px;
  opacity: .7;
}

.flexslider:hover .flex-next {
  right: 10px;
  opacity: .7;
}

.flexslider:hover .flex-next:hover,
.flexslider:hover .flex-prev:hover {
  opacity: 1;
}

.flex-disabled {
  opacity: 0 !important;
}

.flex-direction-nav a:before {
  font-family: 'ElegantIcons';
  font-size: 38px;
  position: relative;
  top: -3px;
  left: 7px;
  display: inline-block;
  padding-bottom: 22px;
  content: '4';
  color: #fff;
}

.flex-direction-nav a.flex-next:before {
  right: 8px;
  left: -9px;
  content: '5';
}

/* Pause/Play */
.flex-pauseplay a {
  position: absolute;
  z-index: 10;
  bottom: 5px;
  left: 10px;
  display: block;
  overflow: hidden;
  width: 20px;
  height: 20px;
  cursor: pointer;
  opacity: .8;
  color: #000;
}

.flex-pauseplay a:before {
  font-family: 'flexslider-icon';
  font-size: 20px;
  display: inline-block;
  content: '\f004';
}

.flex-pauseplay a:hover {
  opacity: 1;
}

.flex-pauseplay a.flex-play:before {
  content: '\f003';
}

/* Control Nav */
.flex-control-nav {
  position: absolute;
  z-index: 10;
  bottom: 44px;
  width: 100%;
  text-align: center;
}

.flex-control-nav li {
  display: inline-block;
  zoom: 1;
  margin: 0 5px;
  *display: inline;
}

.flex-control-paging li a {
  display: block;
  width: 10px;
  height: 10px;
  cursor: pointer;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  transition: all .5s ease;
  text-indent: -9999px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 20px;
  border-radius: 50%;
  background: #fff;
  background: rgba(255, 255, 255, .5);
  -o-border-radius: 50%;
}

.flex-control-paging li a:hover {
  background: #fff;
  background: rgba(255, 255, 255, .7);
}

.flex-control-paging li a.flex-active {
  cursor: default;
  background: #fff;
  background: rgba(255, 255, 255, .9);
}

.flex-control-thumbs {
  position: static;
  overflow: hidden;
  margin: 5px 0 0;
}

.flex-control-thumbs li {
  float: left;
  width: 25%;
  margin: 0;
}

.flex-control-thumbs img {
  display: block;
  width: 100%;
  cursor: pointer;
  opacity: .7;
}

.flex-control-thumbs img:hover {
  opacity: 1;
}

.flex-control-thumbs .flex-active {
  cursor: default;
  opacity: 1;
}

@media screen and (max-width: 860px) {
  .flex-direction-nav .flex-prev {
    left: 10px;
    opacity: 1;
  }

  .flex-direction-nav .flex-next {
    right: 10px;
    opacity: 1;
  }
}

@font-face {
  font-family: 'ElegantIcons';
    font-weight: normal;
    font-style: normal; 

    src: url('fonts/ElegantIcons.eot');
    src: url('fonts/ElegantIcons.eot?#iefix') format('embedded-opentype'), url('fonts/ElegantIcons.woff') format('woff'), url('fonts/ElegantIcons.ttf') format('truetype'), url('fonts/ElegantIcons.svg#ElegantIcons') format('svg');
}

/* Use the following CSS code if you want to use data attributes for inserting your icons */
[data-icon]:before {
  font-family: 'ElegantIcons';
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  content: attr(data-icon);
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Use the following CSS code if you want to have a class per icon */
/*
Instead of a list of all class selectors,
you can use the generic selector below, but it's slower:
[class*="your-class-prefix"] {
*/
.arrow_up,
.arrow_down,
.arrow_left,
.arrow_right,
.arrow_left-up,
.arrow_right-up,
.arrow_right-down,
.arrow_left-down,
.arrow-up-down,
.arrow_up-down_alt,
.arrow_left-right_alt,
.arrow_left-right,
.arrow_expand_alt2,
.arrow_expand_alt,
.arrow_condense,
.arrow_expand,
.arrow_move,
.arrow_carrot-up,
.arrow_carrot-down,
.arrow_carrot-left,
.arrow_carrot-right,
.arrow_carrot-2up,
.arrow_carrot-2down,
.arrow_carrot-2left,
.arrow_carrot-2right,
.arrow_carrot-up_alt2,
.arrow_carrot-down_alt2,
.arrow_carrot-left_alt2,
.arrow_carrot-right_alt2,
.arrow_carrot-2up_alt2,
.arrow_carrot-2down_alt2,
.arrow_carrot-2left_alt2,
.arrow_carrot-2right_alt2,
.arrow_triangle-up,
.arrow_triangle-down,
.arrow_triangle-left,
.arrow_triangle-right,
.arrow_triangle-up_alt2,
.arrow_triangle-down_alt2,
.arrow_triangle-left_alt2,
.arrow_triangle-right_alt2,
.arrow_back,
.icon_minus-06,
.icon_plus,
.icon_close,
.icon_check,
.icon_minus_alt2,
.icon_plus_alt2,
.icon_close_alt2,
.icon_check_alt2,
.icon_zoom-out_alt,
.icon_zoom-in_alt,
.icon_search,
.icon_box-empty,
.icon_box-selected,
.icon_minus-box,
.icon_plus-box,
.icon_box-checked,
.icon_circle-empty,
.icon_circle-slelected,
.icon_stop_alt2,
.icon_stop,
.icon_pause_alt2,
.icon_pause,
.icon_menu,
.icon_menu-square_alt2,
.icon_menu-circle_alt2,
.icon_ul,
.icon_ol,
.icon_adjust-horiz,
.icon_adjust-vert,
.icon_document_alt,
.icon_documents_alt,
.icon_pencil,
.icon_pencil-edit_alt,
.icon_pencil-edit,
.icon_folder-alt,
.icon_folder-open_alt,
.icon_folder-add_alt,
.icon_info_alt,
.icon_error-oct_alt,
.icon_error-circle_alt,
.icon_error-triangle_alt,
.icon_question_alt2,
.icon_question,
.icon_comment_alt,
.icon_chat_alt,
.icon_vol-mute_alt,
.icon_volume-low_alt,
.icon_volume-high_alt,
.icon_quotations,
.icon_quotations_alt2,
.icon_clock_alt,
.icon_lock_alt,
.icon_lock-open_alt,
.icon_key_alt,
.icon_cloud_alt,
.icon_cloud-upload_alt,
.icon_cloud-download_alt,
.icon_image,
.icon_images,
.icon_lightbulb_alt,
.icon_gift_alt,
.icon_house_alt,
.icon_genius,
.icon_mobile,
.icon_tablet,
.icon_laptop,
.icon_desktop,
.icon_camera_alt,
.icon_mail_alt,
.icon_cone_alt,
.icon_ribbon_alt,
.icon_bag_alt,
.icon_creditcard,
.icon_cart_alt,
.icon_paperclip,
.icon_tag_alt,
.icon_tags_alt,
.icon_trash_alt,
.icon_cursor_alt,
.icon_mic_alt,
.icon_compass_alt,
.icon_pin_alt,
.icon_pushpin_alt,
.icon_map_alt,
.icon_drawer_alt,
.icon_toolbox_alt,
.icon_book_alt,
.icon_calendar,
.icon_film,
.icon_table,
.icon_contacts_alt,
.icon_headphones,
.icon_lifesaver,
.icon_piechart,
.icon_refresh,
.icon_link_alt,
.icon_link,
.icon_loading,
.icon_blocked,
.icon_archive_alt,
.icon_heart_alt,
.icon_star_alt,
.icon_star-half_alt,
.icon_star,
.icon_star-half,
.icon_tools,
.icon_tool,
.icon_cog,
.icon_cogs,
.arrow_up_alt,
.arrow_down_alt,
.arrow_left_alt,
.arrow_right_alt,
.arrow_left-up_alt,
.arrow_right-up_alt,
.arrow_right-down_alt,
.arrow_left-down_alt,
.arrow_condense_alt,
.arrow_expand_alt3,
.arrow_carrot_up_alt,
.arrow_carrot-down_alt,
.arrow_carrot-left_alt,
.arrow_carrot-right_alt,
.arrow_carrot-2up_alt,
.arrow_carrot-2dwnn_alt,
.arrow_carrot-2left_alt,
.arrow_carrot-2right_alt,
.arrow_triangle-up_alt,
.arrow_triangle-down_alt,
.arrow_triangle-left_alt,
.arrow_triangle-right_alt,
.icon_minus_alt,
.icon_plus_alt,
.icon_close_alt,
.icon_check_alt,
.icon_zoom-out,
.icon_zoom-in,
.icon_stop_alt,
.icon_menu-square_alt,
.icon_menu-circle_alt,
.icon_document,
.icon_documents,
.icon_pencil_alt,
.icon_folder,
.icon_folder-open,
.icon_folder-add,
.icon_folder_upload,
.icon_folder_download,
.icon_info,
.icon_error-circle,
.icon_error-oct,
.icon_error-triangle,
.icon_question_alt,
.icon_comment,
.icon_chat,
.icon_vol-mute,
.icon_volume-low,
.icon_volume-high,
.icon_quotations_alt,
.icon_clock,
.icon_lock,
.icon_lock-open,
.icon_key,
.icon_cloud,
.icon_cloud-upload,
.icon_cloud-download,
.icon_lightbulb,
.icon_gift,
.icon_house,
.icon_camera,
.icon_mail,
.icon_cone,
.icon_ribbon,
.icon_bag,
.icon_cart,
.icon_tag,
.icon_tags,
.icon_trash,
.icon_cursor,
.icon_mic,
.icon_compass,
.icon_pin,
.icon_pushpin,
.icon_map,
.icon_drawer,
.icon_toolbox,
.icon_book,
.icon_contacts,
.icon_archive,
.icon_heart,
.icon_profile,
.icon_group,
.icon_grid-2x2,
.icon_grid-3x3,
.icon_music,
.icon_pause_alt,
.icon_phone,
.icon_upload,
.icon_download,
.social_facebook,
.social_twitter,
.social_pinterest,
.social_googleplus,
.social_tumblr,
.social_tumbleupon,
.social_wordpress,
.social_instagram,
.social_dribbble,
.social_vimeo,
.social_linkedin,
.social_rss,
.social_deviantart,
.social_share,
.social_myspace,
.social_skype,
.social_youtube,
.social_picassa,
.social_googledrive,
.social_flickr,
.social_blogger,
.social_spotify,
.social_delicious,
.social_facebook_circle,
.social_twitter_circle,
.social_pinterest_circle,
.social_googleplus_circle,
.social_tumblr_circle,
.social_stumbleupon_circle,
.social_wordpress_circle,
.social_instagram_circle,
.social_dribbble_circle,
.social_vimeo_circle,
.social_linkedin_circle,
.social_rss_circle,
.social_deviantart_circle,
.social_share_circle,
.social_myspace_circle,
.social_skype_circle,
.social_youtube_circle,
.social_picassa_circle,
.social_googledrive_alt2,
.social_flickr_circle,
.social_blogger_circle,
.social_spotify_circle,
.social_delicious_circle,
.social_facebook_square,
.social_twitter_square,
.social_pinterest_square,
.social_googleplus_square,
.social_tumblr_square,
.social_stumbleupon_square,
.social_wordpress_square,
.social_instagram_square,
.social_dribbble_square,
.social_vimeo_square,
.social_linkedin_square,
.social_rss_square,
.social_deviantart_square,
.social_share_square,
.social_myspace_square,
.social_skype_square,
.social_youtube_square,
.social_picassa_square,
.social_googledrive_square,
.social_flickr_square,
.social_blogger_square,
.social_spotify_square,
.social_delicious_square,
.icon_printer,
.icon_calulator,
.icon_building,
.icon_floppy,
.icon_drive,
.icon_search-2,
.icon_id,
.icon_id-2,
.icon_puzzle,
.icon_like,
.icon_dislike,
.icon_mug,
.icon_currency,
.icon_wallet,
.icon_pens,
.icon_easel,
.icon_flowchart,
.icon_datareport,
.icon_briefcase,
.icon_shield,
.icon_percent,
.icon_globe,
.icon_globe-2,
.icon_target,
.icon_hourglass,
.icon_balance,
.icon_rook,
.icon_printer-alt,
.icon_calculator_alt,
.icon_building_alt,
.icon_floppy_alt,
.icon_drive_alt,
.icon_search_alt,
.icon_id_alt,
.icon_id-2_alt,
.icon_puzzle_alt,
.icon_like_alt,
.icon_dislike_alt,
.icon_mug_alt,
.icon_currency_alt,
.icon_wallet_alt,
.icon_pens_alt,
.icon_easel_alt,
.icon_flowchart_alt,
.icon_datareport_alt,
.icon_briefcase_alt,
.icon_shield_alt,
.icon_percent_alt,
.icon_globe_alt,
.icon_clipboard
{
    font-family: 'ElegantIcons';
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    line-height: 1;

    text-transform: none;

    speak: none;
    -webkit-font-smoothing: antialiased;
}

.arrow_up:before
{
    content: '\21';
}

.arrow_down:before
{
    content: '\22';
}

.arrow_left:before
{
    content: '\23';
}

.arrow_right:before
{
    content: '\24';
}

.arrow_left-up:before
{
    content: '\25';
}

.arrow_right-up:before
{
    content: '\26';
}

.arrow_right-down:before
{
    content: '\27';
}

.arrow_left-down:before
{
    content: '\28';
}

.arrow-up-down:before
{
    content: '\29';
}

.arrow_up-down_alt:before
{
    content: '\2a';
}

.arrow_left-right_alt:before
{
    content: '\2b';
}

.arrow_left-right:before
{
    content: '\2c';
}

.arrow_expand_alt2:before
{
    content: '\2d';
}

.arrow_expand_alt:before
{
    content: '\2e';
}

.arrow_condense:before
{
    content: '\2f';
}

.arrow_expand:before
{
    content: '\30';
}

.arrow_move:before
{
    content: '\31';
}

.arrow_carrot-up:before
{
    content: '\32';
}

.arrow_carrot-down:before
{
    content: '\33';
}

.arrow_carrot-left:before
{
    content: '\34';
}

.arrow_carrot-right:before
{
    content: '\35';
}

.arrow_carrot-2up:before
{
    content: '\36';
}

.arrow_carrot-2down:before
{
    content: '\37';
}

.arrow_carrot-2left:before
{
    content: '\38';
}

.arrow_carrot-2right:before
{
    content: '\39';
}

.arrow_carrot-up_alt2:before
{
    content: '\3a';
}

.arrow_carrot-down_alt2:before
{
    content: '\3b';
}

.arrow_carrot-left_alt2:before
{
    content: '\3c';
}

.arrow_carrot-right_alt2:before
{
    content: '\3d';
}

.arrow_carrot-2up_alt2:before
{
    content: '\3e';
}

.arrow_carrot-2down_alt2:before
{
    content: '\3f';
}

.arrow_carrot-2left_alt2:before
{
    content: '\40';
}

.arrow_carrot-2right_alt2:before
{
    content: '\41';
}

.arrow_triangle-up:before
{
    content: '\42';
}

.arrow_triangle-down:before
{
    content: '\43';
}

.arrow_triangle-left:before
{
    content: '\44';
}

.arrow_triangle-right:before
{
    content: '\45';
}

.arrow_triangle-up_alt2:before
{
    content: '\46';
}

.arrow_triangle-down_alt2:before
{
    content: '\47';
}

.arrow_triangle-left_alt2:before
{
    content: '\48';
}

.arrow_triangle-right_alt2:before
{
    content: '\49';
}

.arrow_back:before
{
    content: '\4a';
}

.icon_minus-06:before
{
    content: '\4b';
}

.icon_plus:before
{
    content: '\4c';
}

.icon_close:before
{
    content: '\4d';
}

.icon_check:before
{
    content: '\4e';
}

.icon_minus_alt2:before
{
    content: '\4f';
}

.icon_plus_alt2:before
{
    content: '\50';
}

.icon_close_alt2:before
{
    content: '\51';
}

.icon_check_alt2:before
{
    content: '\52';
}

.icon_zoom-out_alt:before
{
    content: '\53';
}

.icon_zoom-in_alt:before
{
    content: '\54';
}

.icon_search:before
{
    content: '\55';
}

.icon_box-empty:before
{
    content: '\56';
}

.icon_box-selected:before
{
    content: '\57';
}

.icon_minus-box:before
{
    content: '\58';
}

.icon_plus-box:before
{
    content: '\59';
}

.icon_box-checked:before
{
    content: '\5a';
}

.icon_circle-empty:before
{
    content: '\5b';
}

.icon_circle-slelected:before
{
    content: '\5c';
}

.icon_stop_alt2:before
{
    content: '\5d';
}

.icon_stop:before
{
    content: '\5e';
}

.icon_pause_alt2:before
{
    content: '\5f';
}

.icon_pause:before
{
    content: '\60';
}

.icon_menu:before
{
    content: '\61';
}

.icon_menu-square_alt2:before
{
    content: '\62';
}

.icon_menu-circle_alt2:before
{
    content: '\63';
}

.icon_ul:before
{
    content: '\64';
}

.icon_ol:before
{
    content: '\65';
}

.icon_adjust-horiz:before
{
    content: '\66';
}

.icon_adjust-vert:before
{
    content: '\67';
}

.icon_document_alt:before
{
    content: '\68';
}

.icon_documents_alt:before
{
    content: '\69';
}

.icon_pencil:before
{
    content: '\6a';
}

.icon_pencil-edit_alt:before
{
    content: '\6b';
}

.icon_pencil-edit:before
{
    content: '\6c';
}

.icon_folder-alt:before
{
    content: '\6d';
}

.icon_folder-open_alt:before
{
    content: '\6e';
}

.icon_folder-add_alt:before
{
    content: '\6f';
}

.icon_info_alt:before
{
    content: '\70';
}

.icon_error-oct_alt:before
{
    content: '\71';
}

.icon_error-circle_alt:before
{
    content: '\72';
}

.icon_error-triangle_alt:before
{
    content: '\73';
}

.icon_question_alt2:before
{
    content: '\74';
}

.icon_question:before
{
    content: '\75';
}

.icon_comment_alt:before
{
    content: '\76';
}

.icon_chat_alt:before
{
    content: '\77';
}

.icon_vol-mute_alt:before
{
    content: '\78';
}

.icon_volume-low_alt:before
{
    content: '\79';
}

.icon_volume-high_alt:before
{
    content: '\7a';
}

.icon_quotations:before
{
    content: '\7b';
}

.icon_quotations_alt2:before
{
    content: '\7c';
}

.icon_clock_alt:before
{
    content: '\7d';
}

.icon_lock_alt:before
{
    content: '\7e';
}

.icon_lock-open_alt:before
{
    content: '\e000';
}

.icon_key_alt:before
{
    content: '\e001';
}

.icon_cloud_alt:before
{
    content: '\e002';
}

.icon_cloud-upload_alt:before
{
    content: '\e003';
}

.icon_cloud-download_alt:before
{
    content: '\e004';
}

.icon_image:before
{
    content: '\e005';
}

.icon_images:before
{
    content: '\e006';
}

.icon_lightbulb_alt:before
{
    content: '\e007';
}

.icon_gift_alt:before
{
    content: '\e008';
}

.icon_house_alt:before
{
    content: '\e009';
}

.icon_genius:before
{
    content: '\e00a';
}

.icon_mobile:before
{
    content: '\e00b';
}

.icon_tablet:before
{
    content: '\e00c';
}

.icon_laptop:before
{
    content: '\e00d';
}

.icon_desktop:before
{
    content: '\e00e';
}

.icon_camera_alt:before
{
    content: '\e00f';
}

.icon_mail_alt:before
{
    content: '\e010';
}

.icon_cone_alt:before
{
    content: '\e011';
}

.icon_ribbon_alt:before
{
    content: '\e012';
}

.icon_bag_alt:before
{
    content: '\e013';
}

.icon_creditcard:before
{
    content: '\e014';
}

.icon_cart_alt:before
{
    content: '\e015';
}

.icon_paperclip:before
{
    content: '\e016';
}

.icon_tag_alt:before
{
    content: '\e017';
}

.icon_tags_alt:before
{
    content: '\e018';
}

.icon_trash_alt:before
{
    content: '\e019';
}

.icon_cursor_alt:before
{
    content: '\e01a';
}

.icon_mic_alt:before
{
    content: '\e01b';
}

.icon_compass_alt:before
{
    content: '\e01c';
}

.icon_pin_alt:before
{
    content: '\e01d';
}

.icon_pushpin_alt:before
{
    content: '\e01e';
}

.icon_map_alt:before
{
    content: '\e01f';
}

.icon_drawer_alt:before
{
    content: '\e020';
}

.icon_toolbox_alt:before
{
    content: '\e021';
}

.icon_book_alt:before
{
    content: '\e022';
}

.icon_calendar:before
{
    content: '\e023';
}

.icon_film:before
{
    content: '\e024';
}

.icon_table:before
{
    content: '\e025';
}

.icon_contacts_alt:before
{
    content: '\e026';
}

.icon_headphones:before
{
    content: '\e027';
}

.icon_lifesaver:before
{
    content: '\e028';
}

.icon_piechart:before
{
    content: '\e029';
}

.icon_refresh:before
{
    content: '\e02a';
}

.icon_link_alt:before
{
    content: '\e02b';
}

.icon_link:before
{
    content: '\e02c';
}

.icon_loading:before
{
    content: '\e02d';
}

.icon_blocked:before
{
    content: '\e02e';
}

.icon_archive_alt:before
{
    content: '\e02f';
}

.icon_heart_alt:before
{
    content: '\e030';
}

.icon_star_alt:before
{
    content: '\e031';
}

.icon_star-half_alt:before
{
    content: '\e032';
}

.icon_star:before
{
    content: '\e033';
}

.icon_star-half:before
{
    content: '\e034';
}

.icon_tools:before
{
    content: '\e035';
}

.icon_tool:before
{
    content: '\e036';
}

.icon_cog:before
{
    content: '\e037';
}

.icon_cogs:before
{
    content: '\e038';
}

.arrow_up_alt:before
{
    content: '\e039';
}

.arrow_down_alt:before
{
    content: '\e03a';
}

.arrow_left_alt:before
{
    content: '\e03b';
}

.arrow_right_alt:before
{
    content: '\e03c';
}

.arrow_left-up_alt:before
{
    content: '\e03d';
}

.arrow_right-up_alt:before
{
    content: '\e03e';
}

.arrow_right-down_alt:before
{
    content: '\e03f';
}

.arrow_left-down_alt:before
{
    content: '\e040';
}

.arrow_condense_alt:before
{
    content: '\e041';
}

.arrow_expand_alt3:before
{
    content: '\e042';
}

.arrow_carrot_up_alt:before
{
    content: '\e043';
}

.arrow_carrot-down_alt:before
{
    content: '\e044';
}

.arrow_carrot-left_alt:before
{
    content: '\e045';
}

.arrow_carrot-right_alt:before
{
    content: '\e046';
}

.arrow_carrot-2up_alt:before
{
    content: '\e047';
}

.arrow_carrot-2dwnn_alt:before
{
    content: '\e048';
}

.arrow_carrot-2left_alt:before
{
    content: '\e049';
}

.arrow_carrot-2right_alt:before
{
    content: '\e04a';
}

.arrow_triangle-up_alt:before
{
    content: '\e04b';
}

.arrow_triangle-down_alt:before
{
    content: '\e04c';
}

.arrow_triangle-left_alt:before
{
    content: '\e04d';
}

.arrow_triangle-right_alt:before
{
    content: '\e04e';
}

.icon_minus_alt:before
{
    content: '\e04f';
}

.icon_plus_alt:before
{
    content: '\e050';
}

.icon_close_alt:before
{
    content: '\e051';
}

.icon_check_alt:before
{
    content: '\e052';
}

.icon_zoom-out:before
{
    content: '\e053';
}

.icon_zoom-in:before
{
    content: '\e054';
}

.icon_stop_alt:before
{
    content: '\e055';
}

.icon_menu-square_alt:before
{
    content: '\e056';
}

.icon_menu-circle_alt:before
{
    content: '\e057';
}

.icon_document:before
{
    content: '\e058';
}

.icon_documents:before
{
    content: '\e059';
}

.icon_pencil_alt:before
{
    content: '\e05a';
}

.icon_folder:before
{
    content: '\e05b';
}

.icon_folder-open:before
{
    content: '\e05c';
}

.icon_folder-add:before
{
    content: '\e05d';
}

.icon_folder_upload:before
{
    content: '\e05e';
}

.icon_folder_download:before
{
    content: '\e05f';
}

.icon_info:before
{
    content: '\e060';
}

.icon_error-circle:before
{
    content: '\e061';
}

.icon_error-oct:before
{
    content: '\e062';
}

.icon_error-triangle:before
{
    content: '\e063';
}

.icon_question_alt:before
{
    content: '\e064';
}

.icon_comment:before
{
    content: '\e065';
}

.icon_chat:before
{
    content: '\e066';
}

.icon_vol-mute:before
{
    content: '\e067';
}

.icon_volume-low:before
{
    content: '\e068';
}

.icon_volume-high:before
{
    content: '\e069';
}

.icon_quotations_alt:before
{
    content: '\e06a';
}

.icon_clock:before
{
    content: '\e06b';
}

.icon_lock:before
{
    content: '\e06c';
}

.icon_lock-open:before
{
    content: '\e06d';
}

.icon_key:before
{
    content: '\e06e';
}

.icon_cloud:before
{
    content: '\e06f';
}

.icon_cloud-upload:before
{
    content: '\e070';
}

.icon_cloud-download:before
{
    content: '\e071';
}

.icon_lightbulb:before
{
    content: '\e072';
}

.icon_gift:before
{
    content: '\e073';
}

.icon_house:before
{
    content: '\e074';
}

.icon_camera:before
{
    content: '\e075';
}

.icon_mail:before
{
    content: '\e076';
}

.icon_cone:before
{
    content: '\e077';
}

.icon_ribbon:before
{
    content: '\e078';
}

.icon_bag:before
{
    content: '\e079';
}

.icon_cart:before
{
    content: '\e07a';
}

.icon_tag:before
{
    content: '\e07b';
}

.icon_tags:before
{
    content: '\e07c';
}

.icon_trash:before
{
    content: '\e07d';
}

.icon_cursor:before
{
    content: '\e07e';
}

.icon_mic:before
{
    content: '\e07f';
}

.icon_compass:before
{
    content: '\e080';
}

.icon_pin:before
{
    content: '\e081';
}

.icon_pushpin:before
{
    content: '\e082';
}

.icon_map:before
{
    content: '\e083';
}

.icon_drawer:before
{
    content: '\e084';
}

.icon_toolbox:before
{
    content: '\e085';
}

.icon_book:before
{
    content: '\e086';
}

.icon_contacts:before
{
    content: '\e087';
}

.icon_archive:before
{
    content: '\e088';
}

.icon_heart:before
{
    content: '\e089';
}

.icon_profile:before
{
    content: '\e08a';
}

.icon_group:before
{
    content: '\e08b';
}

.icon_grid-2x2:before
{
    content: '\e08c';
}

.icon_grid-3x3:before
{
    content: '\e08d';
}

.icon_music:before
{
    content: '\e08e';
}

.icon_pause_alt:before
{
    content: '\e08f';
}

.icon_phone:before
{
    content: '\e090';
}

.icon_upload:before
{
    content: '\e091';
}

.icon_download:before
{
    content: '\e092';
}

.social_facebook:before
{
    content: '\e093';
}

.social_twitter:before
{
    content: '\e094';
}

.social_pinterest:before
{
    content: '\e095';
}

.social_googleplus:before
{
    content: '\e096';
}

.social_tumblr:before
{
    content: '\e097';
}

.social_tumbleupon:before
{
    content: '\e098';
}

.social_wordpress:before
{
    content: '\e099';
}

.social_instagram:before
{
    content: '\e09a';
}

.social_dribbble:before
{
    content: '\e09b';
}

.social_vimeo:before
{
    content: '\e09c';
}

.social_linkedin:before
{
    content: '\e09d';
}

.social_rss:before
{
    content: '\e09e';
}

.social_deviantart:before
{
    content: '\e09f';
}

.social_share:before
{
    content: '\e0a0';
}

.social_myspace:before
{
    content: '\e0a1';
}

.social_skype:before
{
    content: '\e0a2';
}

.social_youtube:before
{
    content: '\e0a3';
}

.social_picassa:before
{
    content: '\e0a4';
}

.social_googledrive:before
{
    content: '\e0a5';
}

.social_flickr:before
{
    content: '\e0a6';
}

.social_blogger:before
{
    content: '\e0a7';
}

.social_spotify:before
{
    content: '\e0a8';
}

.social_delicious:before
{
    content: '\e0a9';
}

.social_facebook_circle:before
{
    content: '\e0aa';
}

.social_twitter_circle:before
{
    content: '\e0ab';
}

.social_pinterest_circle:before
{
    content: '\e0ac';
}

.social_googleplus_circle:before
{
    content: '\e0ad';
}

.social_tumblr_circle:before
{
    content: '\e0ae';
}

.social_stumbleupon_circle:before
{
    content: '\e0af';
}

.social_wordpress_circle:before
{
    content: '\e0b0';
}

.social_instagram_circle:before
{
    content: '\e0b1';
}

.social_dribbble_circle:before
{
    content: '\e0b2';
}

.social_vimeo_circle:before
{
    content: '\e0b3';
}

.social_linkedin_circle:before
{
    content: '\e0b4';
}

.social_rss_circle:before
{
    content: '\e0b5';
}

.social_deviantart_circle:before
{
    content: '\e0b6';
}

.social_share_circle:before
{
    content: '\e0b7';
}

.social_myspace_circle:before
{
    content: '\e0b8';
}

.social_skype_circle:before
{
    content: '\e0b9';
}

.social_youtube_circle:before
{
    content: '\e0ba';
}

.social_picassa_circle:before
{
    content: '\e0bb';
}

.social_googledrive_alt2:before
{
    content: '\e0bc';
}

.social_flickr_circle:before
{
    content: '\e0bd';
}

.social_blogger_circle:before
{
    content: '\e0be';
}

.social_spotify_circle:before
{
    content: '\e0bf';
}

.social_delicious_circle:before
{
    content: '\e0c0';
}

.social_facebook_square:before
{
    content: '\e0c1';
}

.social_twitter_square:before
{
    content: '\e0c2';
}

.social_pinterest_square:before
{
    content: '\e0c3';
}

.social_googleplus_square:before
{
    content: '\e0c4';
}

.social_tumblr_square:before
{
    content: '\e0c5';
}

.social_stumbleupon_square:before
{
    content: '\e0c6';
}

.social_wordpress_square:before
{
    content: '\e0c7';
}

.social_instagram_square:before
{
    content: '\e0c8';
}

.social_dribbble_square:before
{
    content: '\e0c9';
}

.social_vimeo_square:before
{
    content: '\e0ca';
}

.social_linkedin_square:before
{
    content: '\e0cb';
}

.social_rss_square:before
{
    content: '\e0cc';
}

.social_deviantart_square:before
{
    content: '\e0cd';
}

.social_share_square:before
{
    content: '\e0ce';
}

.social_myspace_square:before
{
    content: '\e0cf';
}

.social_skype_square:before
{
    content: '\e0d0';
}

.social_youtube_square:before
{
    content: '\e0d1';
}

.social_picassa_square:before
{
    content: '\e0d2';
}

.social_googledrive_square:before
{
    content: '\e0d3';
}

.social_flickr_square:before
{
    content: '\e0d4';
}

.social_blogger_square:before
{
    content: '\e0d5';
}

.social_spotify_square:before
{
    content: '\e0d6';
}

.social_delicious_square:before
{
    content: '\e0d7';
}

.icon_printer:before
{
    content: '\e103';
}

.icon_calulator:before
{
    content: '\e0ee';
}

.icon_building:before
{
    content: '\e0ef';
}

.icon_floppy:before
{
    content: '\e0e8';
}

.icon_drive:before
{
    content: '\e0ea';
}

.icon_search-2:before
{
    content: '\e101';
}

.icon_id:before
{
    content: '\e107';
}

.icon_id-2:before
{
    content: '\e108';
}

.icon_puzzle:before
{
    content: '\e102';
}

.icon_like:before
{
    content: '\e106';
}

.icon_dislike:before
{
    content: '\e0eb';
}

.icon_mug:before
{
    content: '\e105';
}

.icon_currency:before
{
    content: '\e0ed';
}

.icon_wallet:before
{
    content: '\e100';
}

.icon_pens:before
{
    content: '\e104';
}

.icon_easel:before
{
    content: '\e0e9';
}

.icon_flowchart:before
{
    content: '\e109';
}

.icon_datareport:before
{
    content: '\e0ec';
}

.icon_briefcase:before
{
    content: '\e0fe';
}

.icon_shield:before
{
    content: '\e0f6';
}

.icon_percent:before
{
    content: '\e0fb';
}

.icon_globe:before
{
    content: '\e0e2';
}

.icon_globe-2:before
{
    content: '\e0e3';
}

.icon_target:before
{
    content: '\e0f5';
}

.icon_hourglass:before
{
    content: '\e0e1';
}

.icon_balance:before
{
    content: '\e0ff';
}

.icon_rook:before
{
    content: '\e0f8';
}

.icon_printer-alt:before
{
    content: '\e0fa';
}

.icon_calculator_alt:before
{
    content: '\e0e7';
}

.icon_building_alt:before
{
    content: '\e0fd';
}

.icon_floppy_alt:before
{
    content: '\e0e4';
}

.icon_drive_alt:before
{
    content: '\e0e5';
}

.icon_search_alt:before
{
    content: '\e0f7';
}

.icon_id_alt:before
{
    content: '\e0e0';
}

.icon_id-2_alt:before
{
    content: '\e0fc';
}

.icon_puzzle_alt:before
{
    content: '\e0f9';
}

.icon_like_alt:before
{
    content: '\e0dd';
}

.icon_dislike_alt:before
{
    content: '\e0f1';
}

.icon_mug_alt:before
{
    content: '\e0dc';
}

.icon_currency_alt:before
{
    content: '\e0f3';
}

.icon_wallet_alt:before
{
    content: '\e0d8';
}

.icon_pens_alt:before
{
    content: '\e0db';
}

.icon_easel_alt:before
{
    content: '\e0f0';
}

.icon_flowchart_alt:before
{
    content: '\e0df';
}

.icon_datareport_alt:before
{
    content: '\e0f2';
}

.icon_briefcase_alt:before
{
    content: '\e0f4';
}

.icon_shield_alt:before
{
    content: '\e0d9';
}

.icon_percent_alt:before
{
    content: '\e0da';
}

.icon_globe_alt:before
{
    content: '\e0de';
}

.icon_clipboard:before
{
    content: '\e0e6';
}

.glyph
{
    float: left;

    width: 6em;
    margin: .4em 1.5em .75em 0;
    padding: .75em;

    text-align: center;

    text-shadow: none;
}

.glyph_big
{
    font-size: 128px;

    float: left;

    margin-right: 20px; 

    color: #59c5dc;
}

.glyph div
{
    padding-bottom: 10px;
}

.glyph input
{
    font-family: consolas, monospace;
    font-size: 12px;

    width: 100%;
    padding: .2em;

    text-align: center;

    border: 0;
    -webkit-border-radius: 5px; 
       -moz-border-radius: 5px;
    box-shadow: 0 0 0 1px #ccc;
}

.centered
{
    margin-right: auto; 
    margin-left: auto;
}

.glyph .fs1
{
    font-size: 2em;
}

@font-face
{
    font-family: 'et-line';
    font-weight: normal;
    font-style: normal; 

    src: url('fonts/et-line.eot');
    src: url('fonts/et-line.eot?#iefix') format('embedded-opentype'), url('fonts/et-line.woff') format('woff'), url('fonts/et-line.ttf') format('truetype'), url('fonts/et-line.svg#et-line') format('svg');
}

/* Use the following CSS code if you want to use data attributes for inserting your icons */
[data-icon]:before
{
    font-family: 'et-line';
    font-weight: normal;
    font-variant: normal;
    line-height: 1;

    display: inline-block; 

    content: attr(data-icon);
    text-transform: none;

    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* Use the following CSS code if you want to have a class per icon */
/*
Instead of a list of all class selectors,
you can use the generic selector below, but it's slower:
[class*="icon-"] {
*/
.icon-mobile,
.icon-laptop,
.icon-desktop,
.icon-tablet,
.icon-phone,
.icon-document,
.icon-documents,
.icon-search,
.icon-clipboard,
.icon-newspaper,
.icon-notebook,
.icon-book-open,
.icon-browser,
.icon-calendar,
.icon-presentation,
.icon-picture,
.icon-pictures,
.icon-video,
.icon-camera,
.icon-printer,
.icon-toolbox,
.icon-briefcase,
.icon-wallet,
.icon-gift,
.icon-bargraph,
.icon-grid,
.icon-expand,
.icon-focus,
.icon-edit,
.icon-adjustments,
.icon-ribbon,
.icon-hourglass,
.icon-lock,
.icon-megaphone,
.icon-shield,
.icon-trophy,
.icon-flag,
.icon-map,
.icon-puzzle,
.icon-basket,
.icon-envelope,
.icon-streetsign,
.icon-telescope,
.icon-gears,
.icon-key,
.icon-paperclip,
.icon-attachment,
.icon-pricetags,
.icon-lightbulb,
.icon-layers,
.icon-pencil,
.icon-tools,
.icon-tools-2,
.icon-scissors,
.icon-paintbrush,
.icon-magnifying-glass,
.icon-circle-compass,
.icon-linegraph,
.icon-mic,
.icon-strategy,
.icon-beaker,
.icon-caution,
.icon-recycle,
.icon-anchor,
.icon-profile-male,
.icon-profile-female,
.icon-bike,
.icon-wine,
.icon-hotairballoon,
.icon-globe,
.icon-genius,
.icon-map-pin,
.icon-dial,
.icon-chat,
.icon-heart,
.icon-cloud,
.icon-upload,
.icon-download,
.icon-target,
.icon-hazardous,
.icon-piechart,
.icon-speedometer,
.icon-global,
.icon-compass,
.icon-lifesaver,
.icon-clock,
.icon-aperture,
.icon-quote,
.icon-scope,
.icon-alarmclock,
.icon-refresh,
.icon-happy,
.icon-sad,
.icon-facebook,
.icon-twitter,
.icon-googleplus,
.icon-rss,
.icon-tumblr,
.icon-linkedin,
.icon-dribbble {
  font-family: 'et-line';
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  display: inline-block;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-mobile:before {
  content: '\e000';
}

.icon-laptop:before {
  content: '\e001';
}

.icon-desktop:before {
  content: '\e002';
}

.icon-tablet:before {
  content: '\e003';
}

.icon-phone:before {
  content: '\e004';
}

.icon-document:before {
  content: '\e005';
}

.icon-documents:before {
  content: '\e006';
}

.icon-search:before {
  content: '\e007';
}

.icon-clipboard:before {
  content: '\e008';
}

.icon-newspaper:before {
  content: '\e009';
}

.icon-notebook:before {
  content: '\e00a';
}

.icon-book-open:before {
  content: '\e00b';
}

.icon-browser:before {
  content: '\e00c';
}

.icon-calendar:before {
  content: '\e00d';
}

.icon-presentation:before {
  content: '\e00e';
}

.icon-picture:before {
  content: '\e00f';
}

.icon-pictures:before {
  content: '\e010';
}

.icon-video:before {
  content: '\e011';
}

.icon-camera:before {
  content: '\e012';
}

.icon-printer:before {
  content: '\e013';
}

.icon-toolbox:before {
  content: '\e014';
}

.icon-briefcase:before {
  content: '\e015';
}

.icon-wallet:before {
  content: '\e016';
}

.icon-gift:before {
  content: '\e017';
}

.icon-bargraph:before {
  content: '\e018';
}

.icon-grid:before {
  content: '\e019';
}

.icon-expand:before {
  content: '\e01a';
}

.icon-focus:before {
  content: '\e01b';
}

.icon-edit:before {
  content: '\e01c';
}

.icon-adjustments:before {
  content: '\e01d';
}

.icon-ribbon:before {
  content: '\e01e';
}

.icon-hourglass:before {
  content: '\e01f';
}

.icon-lock:before {
  content: '\e020';
}

.icon-megaphone:before {
  content: '\e021';
}

.icon-shield:before {
  content: '\e022';
}

.icon-trophy:before {
  content: '\e023';
}

.icon-flag:before {
  content: '\e024';
}

.icon-map:before {
  content: '\e025';
}

.icon-puzzle:before {
  content: '\e026';
}

.icon-basket:before {
  content: '\e027';
}

.icon-envelope:before {
  content: '\e028';
}

.icon-streetsign:before {
  content: '\e029';
}

.icon-telescope:before {
  content: '\e02a';
}

.icon-gears:before {
  content: '\e02b';
}

.icon-key:before {
  content: '\e02c';
}

.icon-paperclip:before {
  content: '\e02d';
}

.icon-attachment:before {
  content: '\e02e';
}

.icon-pricetags:before {
  content: '\e02f';
}

.icon-lightbulb:before {
  content: '\e030';
}

.icon-layers:before {
  content: '\e031';
}

.icon-pencil:before {
  content: '\e032';
}

.icon-tools:before {
  content: '\e033';
}

.icon-tools-2:before {
  content: '\e034';
}

.icon-scissors:before {
  content: '\e035';
}

.icon-paintbrush:before {
  content: '\e036';
}

.icon-magnifying-glass:before {
  content: '\e037';
}

.icon-circle-compass:before {
  content: '\e038';
}

.icon-linegraph:before {
  content: '\e039';
}

.icon-mic:before {
  content: '\e03a';
}

.icon-strategy:before {
  content: '\e03b';
}

.icon-beaker:before {
  content: '\e03c';
}

.icon-caution:before {
  content: '\e03d';
}

.icon-recycle:before {
  content: '\e03e';
}

.icon-anchor:before {
  content: '\e03f';
}

.icon-profile-male:before {
  content: '\e040';
}

.icon-profile-female:before {
  content: '\e041';
}

.icon-bike:before {
  content: '\e042';
}

.icon-wine:before {
  content: '\e043';
}

.icon-hotairballoon:before {
  content: '\e044';
}

.icon-globe:before {
  content: '\e045';
}

.icon-genius:before {
  content: '\e046';
}

.icon-map-pin:before {
  content: '\e047';
}

.icon-dial:before {
  content: '\e048';
}

.icon-chat:before {
  content: '\e049';
}

.icon-heart:before {
  content: '\e04a';
}

.icon-cloud:before {
  content: '\e04b';
}

.icon-upload:before {
  content: '\e04c';
}

.icon-download:before {
  content: '\e04d';
}

.icon-target:before {
  content: '\e04e';
}

.icon-hazardous:before {
  content: '\e04f';
}

.icon-piechart:before {
  content: '\e050';
}

.icon-speedometer:before {
  content: '\e051';
}

.icon-global:before {
  content: '\e052';
}

.icon-compass:before {
  content: '\e053';
}

.icon-lifesaver:before {
  content: '\e054';
}

.icon-clock:before {
  content: '\e055';
}

.icon-aperture:before {
  content: '\e056';
}

.icon-quote:before {
  content: '\e057';
}

.icon-scope:before {
  content: '\e058';
}

.icon-alarmclock:before {
  content: '\e059';
}

.icon-refresh:before {
  content: '\e05a';
}

.icon-happy:before {
  content: '\e05b';
}

.icon-sad:before {
  content: '\e05c';
}

.icon-facebook:before {
  content: '\e05d';
}

.icon-twitter:before {
  content: '\e05e';
}

.icon-googleplus:before {
  content: '\e05f';
}

.icon-rss:before {
  content: '\e060';
}

.icon-tumblr:before {
  content: '\e061';
}

.icon-linkedin:before {
  content: '\e062';
}

.icon-dribbble:before {
  content: '\e063';
}

/*! jQuery UI - v1.11.0 - 2014-06-26
* http://jqueryui.com
* Includes: core.css, accordion.css, autocomplete.css, button.css, datepicker.css, dialog.css, draggable.css, menu.css, progressbar.css, resizable.css, selectable.css, selectmenu.css, slider.css, sortable.css, spinner.css, tabs.css, tooltip.css, theme.css
* To view and modify this theme, visit http://jqueryui.com/themeroller/?ffDefault=Trebuchet%20MS%2CTahoma%2CVerdana%2CArial%2Csans-serif&fwDefault=bold&fsDefault=1.1em&cornerRadius=4px&bgColorHeader=f6a828&bgTextureHeader=gloss_wave&bgImgOpacityHeader=35&borderColorHeader=e78f08&fcHeader=ffffff&iconColorHeader=ffffff&bgColorContent=eeeeee&bgTextureContent=highlight_soft&bgImgOpacityContent=100&borderColorContent=dddddd&fcContent=333333&iconColorContent=222222&bgColorDefault=f6f6f6&bgTextureDefault=glass&bgImgOpacityDefault=100&borderColorDefault=cccccc&fcDefault=1c94c4&iconColorDefault=ef8c08&bgColorHover=fdf5ce&bgTextureHover=glass&bgImgOpacityHover=100&borderColorHover=fbcb09&fcHover=c77405&iconColorHover=ef8c08&bgColorActive=ffffff&bgTextureActive=glass&bgImgOpacityActive=65&borderColorActive=fbd850&fcActive=eb8f00&iconColorActive=ef8c08&bgColorHighlight=ffe45c&bgTextureHighlight=highlight_soft&bgImgOpacityHighlight=75&borderColorHighlight=fed22f&fcHighlight=363636&iconColorHighlight=228ef1&bgColorError=b81900&bgTextureError=diagonals_thick&bgImgOpacityError=18&borderColorError=cd0a0a&fcError=ffffff&iconColorError=ffd27a&bgColorOverlay=666666&bgTextureOverlay=diagonals_thick&bgImgOpacityOverlay=20&opacityOverlay=50&bgColorShadow=000000&bgTextureShadow=flat&bgImgOpacityShadow=10&opacityShadow=20&thicknessShadow=5px&offsetTopShadow=-5px&offsetLeftShadow=-5px&cornerRadiusShadow=5px
* Copyright 2014 jQuery Foundation and other contributors; Licensed MIT */
.ui-helper-hidden {
  display: none;
}

.ui-helper-hidden-accessible {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.ui-helper-reset {
  font-size: 100%;
  line-height: 1.3;
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  border: 0;
  outline: 0;
}

.ui-helper-clearfix:before,
.ui-helper-clearfix:after {
  display: table;
  border-collapse: collapse;
  content: '';
}

.ui-helper-clearfix:after {
  clear: both;
}

.ui-helper-clearfix {
  min-height: 0;
}

.ui-helper-zfix {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  filter: Alpha(Opacity=0);
}

.ui-front {
  z-index: 100;
}

.ui-state-disabled {
  cursor: default !important;
}

.ui-icon {
  display: block;
  overflow: hidden;
  text-indent: -99999px;
  background-repeat: no-repeat;
}

.ui-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ui-accordion .ui-accordion-header {
  font-size: 100%;
  position: relative;
  display: block;
  min-height: 0;
  margin: 2px 0 0 0;
  padding: .5em .5em .5em .7em;
  cursor: pointer;
}

.ui-accordion .ui-accordion-icons {
  padding-left: 2.2em;
}

.ui-accordion .ui-accordion-icons .ui-accordion-icons {
  padding-left: 2.2em;
}

.ui-accordion .ui-accordion-header .ui-accordion-header-icon {
  position: absolute;
  top: 50%;
  left: .5em;
  margin-top: -8px;
}

.ui-accordion .ui-accordion-content {
  overflow: auto;
  padding: 1em 2.2em;
  border-top: 0;
}

.ui-autocomplete {
  position: absolute;
  top: 0;
  left: 0;
  cursor: default;
}

.ui-button {
  line-height: normal;
  position: relative;
  display: inline-block;
  overflow: visible;
  margin-right: .1em;
  padding: 0;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
}

.ui-button,
.ui-button:link,
.ui-button:visited,
.ui-button:hover,
.ui-button:active {
  text-decoration: none;
}

.ui-button-icon-only {
  width: 2.2em;
}

button.ui-button-icon-only {
  width: 2.4em;
}

.ui-button-icons-only {
  width: 3.4em;
}

button.ui-button-icons-only {
  width: 3.7em;
}

.ui-button .ui-button-text {
  line-height: normal;
  display: block;
}

.ui-button-text-only .ui-button-text {
  padding: .4em 1em;
}

.ui-button-icon-only .ui-button-text,
.ui-button-icons-only .ui-button-text {
  padding: .4em;
  text-indent: -9999999px;
}

.ui-button-text-icon-primary .ui-button-text,
.ui-button-text-icons .ui-button-text {
  padding: .4em 1em .4em 2.1em;
}

.ui-button-text-icon-secondary .ui-button-text,
.ui-button-text-icons .ui-button-text {
  padding: .4em 2.1em .4em 1em;
}

.ui-button-text-icons .ui-button-text {
  padding-right: 2.1em;
  padding-left: 2.1em;
}

input.ui-button {
  padding: .4em 1em;
}

.ui-button-icon-only .ui-icon,
.ui-button-text-icon-primary .ui-icon,
.ui-button-text-icon-secondary .ui-icon,
.ui-button-text-icons .ui-icon,
.ui-button-icons-only .ui-icon {
  position: absolute;
  top: 50%;
  margin-top: -8px;
}

.ui-button-icon-only .ui-icon {
  left: 50%;
  margin-left: -8px;
}

.ui-button-text-icon-primary .ui-button-icon-primary,
.ui-button-text-icons .ui-button-icon-primary,
.ui-button-icons-only .ui-button-icon-primary {
  left: .5em;
}

.ui-button-text-icon-secondary .ui-button-icon-secondary,
.ui-button-text-icons .ui-button-icon-secondary,
.ui-button-icons-only .ui-button-icon-secondary {
  right: .5em;
}

.ui-buttonset {
  margin-right: 7px;
}

.ui-buttonset .ui-button {
  margin-right: -.3em;
  margin-left: 0;
}

input.ui-button::-moz-focus-inner,
button.ui-button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.ui-datepicker {
  display: none;
  width: 17em;
  padding: .2em .2em 0;
}

.ui-datepicker .ui-datepicker-header {
  position: relative;
  padding: .2em 0;
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  position: absolute;
  top: 2px;
  width: 1.8em;
  height: 1.8em;
}

.ui-datepicker .ui-datepicker-prev-hover,
.ui-datepicker .ui-datepicker-next-hover {
  top: 1px;
}

.ui-datepicker .ui-datepicker-prev {
  left: 2px;
}

.ui-datepicker .ui-datepicker-next {
  right: 2px;
}

.ui-datepicker .ui-datepicker-prev-hover {
  left: 1px;
}

.ui-datepicker .ui-datepicker-next-hover {
  right: 1px;
}

.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  margin-top: -8px;
  margin-left: -8px;
}

.ui-datepicker .ui-datepicker-title {
  line-height: 1.8em;
  margin: 0 2.3em;
  text-align: center;
}

.ui-datepicker .ui-datepicker-title select {
  font-size: 1em;
  margin: 1px 0;
}

.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
  width: 49%;
}

.ui-datepicker table {
  font-size: .9em;
  width: 100%;
  margin: 0 0 .4em;
  border-collapse: collapse;
}

.ui-datepicker th {
  font-weight: bold;
  padding: .7em .3em;
  text-align: center;
  border: 0;
}

.ui-datepicker td {
  padding: 1px;
  border: 0;
}

.ui-datepicker td span,
.ui-datepicker td a {
  display: block;
  padding: .2em;
  text-align: right;
  text-decoration: none;
}

.ui-datepicker .ui-datepicker-buttonpane {
  margin: .7em 0 0 0;
  padding: 0 .2em;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  background-image: none;
}

.ui-datepicker .ui-datepicker-buttonpane button {
  float: right;
  overflow: visible;
  width: auto;
  margin: .5em .2em .4em;
  padding: .2em .6em .3em .6em;
  cursor: pointer;
}

.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: left;
}

.ui-datepicker.ui-datepicker-multi {
  width: auto;
}

.ui-datepicker-multi .ui-datepicker-group {
  float: left;
}

.ui-datepicker-multi .ui-datepicker-group table {
  width: 95%;
  margin: 0 auto .4em;
}

.ui-datepicker-multi-2 .ui-datepicker-group {
  width: 50%;
}

.ui-datepicker-multi-3 .ui-datepicker-group {
  width: 33.3%;
}

.ui-datepicker-multi-4 .ui-datepicker-group {
  width: 25%;
}

.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
  border-left-width: 0;
}

.ui-datepicker-multi .ui-datepicker-buttonpane {
  clear: left;
}

.ui-datepicker-row-break {
  font-size: 0;
  clear: both;
  width: 100%;
}

.ui-datepicker-rtl {
  direction: rtl;
}

.ui-datepicker-rtl .ui-datepicker-prev {
  right: 2px;
  left: auto;
}

.ui-datepicker-rtl .ui-datepicker-next {
  right: auto;
  left: 2px;
}

.ui-datepicker-rtl .ui-datepicker-prev:hover {
  right: 1px;
  left: auto;
}

.ui-datepicker-rtl .ui-datepicker-next:hover {
  right: auto;
  left: 1px;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane {
  clear: right;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane button {
  float: left;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current,
.ui-datepicker-rtl .ui-datepicker-group {
  float: right;
}

.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
  border-right-width: 0;
  border-left-width: 1px;
}

.ui-dialog {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  padding: .2em;
  outline: 0;
}

.ui-dialog .ui-dialog-titlebar {
  position: relative;
  padding: .4em 1em;
}

.ui-dialog .ui-dialog-title {
  float: left;
  overflow: hidden;
  width: 90%;
  margin: .1em 0;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ui-dialog .ui-dialog-titlebar-close {
  position: absolute;
  top: 50%;
  right: .3em;
  width: 20px;
  height: 20px;
  margin: -10px 0 0 0;
  padding: 1px;
}

.ui-dialog .ui-dialog-content {
  position: relative;
  overflow: auto;
  padding: .5em 1em;
  border: 0;
  background: none;
}

.ui-dialog .ui-dialog-buttonpane {
  margin-top: .5em;
  padding: .3em 1em .5em .4em;
  text-align: left;
  border-width: 1px 0 0 0;
  background-image: none;
}

.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset {
  float: right;
}

.ui-dialog .ui-dialog-buttonpane button {
  margin: .5em .4em .5em 0;
  cursor: pointer;
}

.ui-dialog .ui-resizable-se {
  right: -5px;
  bottom: -5px;
  width: 12px;
  height: 12px;
  background-position: 16px 16px;
}

.ui-draggable .ui-dialog-titlebar {
  cursor: move;
}

.ui-draggable-handle {
  -ms-touch-action: none;
  touch-action: none;
}

.ui-menu {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  outline: none;
}

.ui-menu .ui-menu {
  position: absolute;
}

.ui-menu .ui-menu-item {
  position: relative;
  min-height: 0;
  margin: 0;
  padding: 3px 1em 3px .4em;
  list-style-image: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');
  cursor: pointer;
}

.ui-menu .ui-menu-divider {
  font-size: 0;
  line-height: 0;
  height: 0;
  margin: 5px 0;
  border-width: 1px 0 0 0;
}

.ui-menu .ui-state-focus,
.ui-menu .ui-state-active {
  margin: -1px;
}

.ui-menu-icons {
  position: relative;
}

.ui-menu-icons .ui-menu-item {
  padding-left: 2em;
}

.ui-menu .ui-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: .2em;
  margin: auto 0;
}

.ui-menu .ui-menu-icon {
  right: 0;
  left: auto;
}

.ui-progressbar {
  overflow: hidden;
  height: 2em;
  text-align: left;
}

.ui-progressbar .ui-progressbar-value {
  height: 100%;
  margin: -1px;
}

.ui-progressbar .ui-progressbar-overlay {
  height: 100%;
  opacity: .25;
  background: url('images/animated-overlay.gif');
  filter: alpha(opacity=25);
}

.ui-progressbar-indeterminate .ui-progressbar-value {
  background-image: none;
}

.ui-resizable {
  position: relative;
}

.ui-resizable-handle {
  font-size: .1px;
  position: absolute;
  display: block;
  -ms-touch-action: none;
  touch-action: none;
}

.ui-resizable-disabled .ui-resizable-handle,
.ui-resizable-autohide .ui-resizable-handle {
  display: none;
}

.ui-resizable-n {
  top: -5px;
  left: 0;
  width: 100%;
  height: 7px;
  cursor: n-resize;
}

.ui-resizable-s {
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 7px;
  cursor: s-resize;
}

.ui-resizable-e {
  top: 0;
  right: -5px;
  width: 7px;
  height: 100%;
  cursor: e-resize;
}

.ui-resizable-w {
  top: 0;
  left: -5px;
  width: 7px;
  height: 100%;
  cursor: w-resize;
}

.ui-resizable-se {
  right: 1px;
  bottom: 1px;
  width: 12px;
  height: 12px;
  cursor: se-resize;
}

.ui-resizable-sw {
  bottom: -5px;
  left: -5px;
  width: 9px;
  height: 9px;
  cursor: sw-resize;
}

.ui-resizable-nw {
  top: -5px;
  left: -5px;
  width: 9px;
  height: 9px;
  cursor: nw-resize;
}

.ui-resizable-ne {
  top: -5px;
  right: -5px;
  width: 9px;
  height: 9px;
  cursor: ne-resize;
}

.ui-selectable {
  -ms-touch-action: none;
  touch-action: none;
}

.ui-selectable-helper {
  position: absolute;
  z-index: 100;
  border: 1px dotted black;
}

.ui-selectmenu-menu {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  margin: 0;
  padding: 0;
}

.ui-selectmenu-menu .ui-menu {
  overflow: auto;
  overflow-x: hidden;
  padding-bottom: 1px;
}

.ui-selectmenu-menu .ui-menu .ui-selectmenu-optgroup {
  font-size: 1em;
  font-weight: bold;
  line-height: 1.5;
  height: auto;
  margin: .5em 0 0 0;
  padding: 2px .4em;
  border: 0;
}

.ui-selectmenu-open {
  display: block;
}

.ui-selectmenu-button {
  position: relative;
  display: inline-block;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;
}

.ui-selectmenu-button span.ui-icon {
  position: absolute;
  top: 50%;
  right: .5em;
  left: auto;
  margin-top: -8px;
}

.ui-selectmenu-button span.ui-selectmenu-text {
  line-height: 1.4;
  display: block;
  overflow: hidden;
  padding: .4em 2.1em .4em 1em;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ui-slider {
  position: relative;
  text-align: left;
}

.ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  width: 1.2em;
  height: 1.2em;
  cursor: default;
  -ms-touch-action: none;
  touch-action: none;
}

.ui-slider .ui-slider-range {
  font-size: .7em;
  position: absolute;
  z-index: 1;
  display: block;
  border: 0;
  background-position: 0 0;
}

.ui-slider.ui-state-disabled .ui-slider-handle,
.ui-slider.ui-state-disabled .ui-slider-range {
  filter: inherit;
}

.ui-slider-horizontal {
  height: .8em;
}

.ui-slider-horizontal .ui-slider-handle {
  top: -.3em;
  margin-left: -.6em;
}

.ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%;
}

.ui-slider-horizontal .ui-slider-range-min {
  left: 0;
}

.ui-slider-horizontal .ui-slider-range-max {
  right: 0;
}

.ui-slider-vertical {
  width: .8em;
  height: 100px;
}

.ui-slider-vertical .ui-slider-handle {
  left: -.3em;
  margin-bottom: -.6em;
  margin-left: 0;
}

.ui-slider-vertical .ui-slider-range {
  left: 0;
  width: 100%;
}

.ui-slider-vertical .ui-slider-range-min {
  bottom: 0;
}

.ui-slider-vertical .ui-slider-range-max {
  top: 0;
}

.ui-sortable-handle {
  -ms-touch-action: none;
  touch-action: none;
}

.ui-spinner {
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 0;
  vertical-align: middle;
}

.ui-spinner-input {
  margin: .2em 0;
  margin-right: 22px;
  margin-left: .4em;
  padding: 0;
  vertical-align: middle;
  color: inherit;
  border: none;
  background: none;
}

.ui-spinner-button {
  font-size: .5em;
  position: absolute;
  right: 0;
  display: block;
  overflow: hidden;
  width: 16px;
  height: 50%;
  margin: 0;
  padding: 0;
  cursor: default;
  text-align: center;
}

.ui-spinner a.ui-spinner-button {
  border-top: none;
  border-right: none;
  border-bottom: none;
}

.ui-spinner .ui-icon {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -8px;
}

.ui-spinner-up {
  top: 0;
}

.ui-spinner-down {
  bottom: 0;
}

.ui-spinner .ui-icon-triangle-1-s {
  background-position: -65px -16px;
}

.ui-tabs {
  position: relative;
  padding: .2em;
}

.ui-tabs .ui-tabs-nav {
  margin: 0;
  padding: .2em .2em 0;
}

.ui-tabs .ui-tabs-nav li {
  position: relative;
  top: 0;
  float: left;
  margin: 1px .2em 0 0;
  padding: 0;
  list-style: none;
  white-space: nowrap;
  border-bottom-width: 0;
}

.ui-tabs .ui-tabs-nav .ui-tabs-anchor {
  float: left;
  padding: .5em 1em;
  text-decoration: none;
}

.ui-tabs .ui-tabs-nav li.ui-tabs-active {
  margin-bottom: -1px;
  padding-bottom: 1px;
}

.ui-tabs .ui-tabs-nav li.ui-tabs-active .ui-tabs-anchor,
.ui-tabs .ui-tabs-nav li.ui-state-disabled .ui-tabs-anchor,
.ui-tabs .ui-tabs-nav li.ui-tabs-loading .ui-tabs-anchor {
  cursor: text;
}

.ui-tabs-collapsible .ui-tabs-nav li.ui-tabs-active .ui-tabs-anchor {
  cursor: pointer;
}

.ui-tabs .ui-tabs-panel {
  display: block;
  padding: 1em 1.4em;
  border-width: 0;
  background: none;
}

.ui-tooltip {
  position: absolute;
  z-index: 9999;
  max-width: 300px;
  padding: 8px;
  -webkit-box-shadow: 0 0 5px #aaa;
  box-shadow: 0 0 5px #aaa;
}

body .ui-tooltip {
  border-width: 2px;
}

.ui-widget {
  font-family: Trebuchet MS,Tahoma,Verdana,Arial,sans-serif;
  font-size: 1.1em;
}

.ui-widget .ui-widget {
  font-size: 1em;
}

.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
  font-family: Trebuchet MS,Tahoma,Verdana,Arial,sans-serif;
  font-size: 1em;
}

.ui-widget-content {
  color: #333;
  border: 1px solid #ddd;
  background: #eee;
}

.ui-widget-content a {
  color: #333;
}

.ui-widget-header {
  font-weight: bold;
  color: #fff;
  border: 1px solid #e78f08;
  background: #f6a828;
}

.ui-widget-header a {
  color: #fff;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  font-weight: bold;
  color: #1c94c4;
  border: 1px solid #ccc;
  background: #f6f6f6;
}

.ui-state-default a,
.ui-state-default a:link,
.ui-state-default a:visited {
  text-decoration: none;
  color: #1c94c4;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
  font-weight: bold;
  color: #c77405;
  border: 1px solid #fbcb09;
  background: #fdf5ce;
}

.ui-state-hover a,
.ui-state-hover a:hover,
.ui-state-hover a:link,
.ui-state-hover a:visited,
.ui-state-focus a,
.ui-state-focus a:hover,
.ui-state-focus a:link,
.ui-state-focus a:visited {
  text-decoration: none;
  color: #c77405;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
  font-weight: bold;
  color: #eb8f00;
  border: 1px solid #fbd850;
  background: #fff;
}

.ui-state-active a,
.ui-state-active a:link,
.ui-state-active a:visited {
  text-decoration: none;
  color: #eb8f00;
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  color: #363636;
  border: 1px solid #fed22f;
  background: #ffe45c;
}

.ui-state-highlight a,
.ui-widget-content .ui-state-highlight a,
.ui-widget-header .ui-state-highlight a {
  color: #363636;
}

.ui-state-error,
.ui-widget-content .ui-state-error,
.ui-widget-header .ui-state-error {
  color: #fff;
  border: 1px solid #cd0a0a;
  background: #b81900;
}

.ui-state-error a,
.ui-widget-content .ui-state-error a,
.ui-widget-header .ui-state-error a {
  color: #fff;
}

.ui-state-error-text,
.ui-widget-content .ui-state-error-text,
.ui-widget-header .ui-state-error-text {
  color: #fff;
}

.ui-priority-primary,
.ui-widget-content .ui-priority-primary,
.ui-widget-header .ui-priority-primary {
  font-weight: bold;
}

.ui-priority-secondary,
.ui-widget-content .ui-priority-secondary,
.ui-widget-header .ui-priority-secondary {
  font-weight: normal;
  opacity: .7;
  filter: Alpha(Opacity=70);
}

.ui-state-disabled,
.ui-widget-content .ui-state-disabled,
.ui-widget-header .ui-state-disabled {
  opacity: .35;
  background-image: none;
  filter: Alpha(Opacity=35);
}

.ui-state-disabled .ui-icon {
  filter: Alpha(Opacity=35);
}

.ui-icon {
  width: 16px;
  height: 16px;
}

.ui-state-error .ui-icon,
.ui-state-error-text .ui-icon {
  background-image: url('images/ui-icons_ffd27a_256x240.png');
}

.ui-icon-blank {
  background-position: 16px 16px;
}

.ui-icon-carat-1-n {
  background-position: 0 0;
}

.ui-icon-carat-1-ne {
  background-position: -16px 0;
}

.ui-icon-carat-1-e {
  background-position: -32px 0;
}

.ui-icon-carat-1-se {
  background-position: -48px 0;
}

.ui-icon-carat-1-s {
  background-position: -64px 0;
}

.ui-icon-carat-1-sw {
  background-position: -80px 0;
}

.ui-icon-carat-1-w {
  background-position: -96px 0;
}

.ui-icon-carat-1-nw {
  background-position: -112px 0;
}

.ui-icon-carat-2-n-s {
  background-position: -128px 0;
}

.ui-icon-carat-2-e-w {
  background-position: -144px 0;
}

.ui-icon-triangle-1-n {
  background-position: 0 -16px;
}

.ui-icon-triangle-1-ne {
  background-position: -16px -16px;
}

.ui-icon-triangle-1-e {
  background-position: -32px -16px;
}

.ui-icon-triangle-1-se {
  background-position: -48px -16px;
}

.ui-icon-triangle-1-s {
  background-position: -64px -16px;
}

.ui-icon-triangle-1-sw {
  background-position: -80px -16px;
}

.ui-icon-triangle-1-w {
  background-position: -96px -16px;
}

.ui-icon-triangle-1-nw {
  background-position: -112px -16px;
}

.ui-icon-triangle-2-n-s {
  background-position: -128px -16px;
}

.ui-icon-triangle-2-e-w {
  background-position: -144px -16px;
}

.ui-icon-arrow-1-n {
  background-position: 0 -32px;
}

.ui-icon-arrow-1-ne {
  background-position: -16px -32px;
}

.ui-icon-arrow-1-e {
  background-position: -32px -32px;
}

.ui-icon-arrow-1-se {
  background-position: -48px -32px;
}

.ui-icon-arrow-1-s {
  background-position: -64px -32px;
}

.ui-icon-arrow-1-sw {
  background-position: -80px -32px;
}

.ui-icon-arrow-1-w {
  background-position: -96px -32px;
}

.ui-icon-arrow-1-nw {
  background-position: -112px -32px;
}

.ui-icon-arrow-2-n-s {
  background-position: -128px -32px;
}

.ui-icon-arrow-2-ne-sw {
  background-position: -144px -32px;
}

.ui-icon-arrow-2-e-w {
  background-position: -160px -32px;
}

.ui-icon-arrow-2-se-nw {
  background-position: -176px -32px;
}

.ui-icon-arrowstop-1-n {
  background-position: -192px -32px;
}

.ui-icon-arrowstop-1-e {
  background-position: -208px -32px;
}

.ui-icon-arrowstop-1-s {
  background-position: -224px -32px;
}

.ui-icon-arrowstop-1-w {
  background-position: -240px -32px;
}

.ui-icon-arrowthick-1-n {
  background-position: 0 -48px;
}

.ui-icon-arrowthick-1-ne {
  background-position: -16px -48px;
}

.ui-icon-arrowthick-1-e {
  background-position: -32px -48px;
}

.ui-icon-arrowthick-1-se {
  background-position: -48px -48px;
}

.ui-icon-arrowthick-1-s {
  background-position: -64px -48px;
}

.ui-icon-arrowthick-1-sw {
  background-position: -80px -48px;
}

.ui-icon-arrowthick-1-w {
  background-position: -96px -48px;
}

.ui-icon-arrowthick-1-nw {
  background-position: -112px -48px;
}

.ui-icon-arrowthick-2-n-s {
  background-position: -128px -48px;
}

.ui-icon-arrowthick-2-ne-sw {
  background-position: -144px -48px;
}

.ui-icon-arrowthick-2-e-w {
  background-position: -160px -48px;
}

.ui-icon-arrowthick-2-se-nw {
  background-position: -176px -48px;
}

.ui-icon-arrowthickstop-1-n {
  background-position: -192px -48px;
}

.ui-icon-arrowthickstop-1-e {
  background-position: -208px -48px;
}

.ui-icon-arrowthickstop-1-s {
  background-position: -224px -48px;
}

.ui-icon-arrowthickstop-1-w {
  background-position: -240px -48px;
}

.ui-icon-arrowreturnthick-1-w {
  background-position: 0 -64px;
}

.ui-icon-arrowreturnthick-1-n {
  background-position: -16px -64px;
}

.ui-icon-arrowreturnthick-1-e {
  background-position: -32px -64px;
}

.ui-icon-arrowreturnthick-1-s {
  background-position: -48px -64px;
}

.ui-icon-arrowreturn-1-w {
  background-position: -64px -64px;
}

.ui-icon-arrowreturn-1-n {
  background-position: -80px -64px;
}

.ui-icon-arrowreturn-1-e {
  background-position: -96px -64px;
}

.ui-icon-arrowreturn-1-s {
  background-position: -112px -64px;
}

.ui-icon-arrowrefresh-1-w {
  background-position: -128px -64px;
}

.ui-icon-arrowrefresh-1-n {
  background-position: -144px -64px;
}

.ui-icon-arrowrefresh-1-e {
  background-position: -160px -64px;
}

.ui-icon-arrowrefresh-1-s {
  background-position: -176px -64px;
}

.ui-icon-arrow-4 {
  background-position: 0 -80px;
}

.ui-icon-arrow-4-diag {
  background-position: -16px -80px;
}

.ui-icon-extlink {
  background-position: -32px -80px;
}

.ui-icon-newwin {
  background-position: -48px -80px;
}

.ui-icon-refresh {
  background-position: -64px -80px;
}

.ui-icon-shuffle {
  background-position: -80px -80px;
}

.ui-icon-transfer-e-w {
  background-position: -96px -80px;
}

.ui-icon-transferthick-e-w {
  background-position: -112px -80px;
}

.ui-icon-folder-collapsed {
  background-position: 0 -96px;
}

.ui-icon-folder-open {
  background-position: -16px -96px;
}

.ui-icon-document {
  background-position: -32px -96px;
}

.ui-icon-document-b {
  background-position: -48px -96px;
}

.ui-icon-note {
  background-position: -64px -96px;
}

.ui-icon-mail-closed {
  background-position: -80px -96px;
}

.ui-icon-mail-open {
  background-position: -96px -96px;
}

.ui-icon-suitcase {
  background-position: -112px -96px;
}

.ui-icon-comment {
  background-position: -128px -96px;
}

.ui-icon-person {
  background-position: -144px -96px;
}

.ui-icon-print {
  background-position: -160px -96px;
}

.ui-icon-trash {
  background-position: -176px -96px;
}

.ui-icon-locked {
  background-position: -192px -96px;
}

.ui-icon-unlocked {
  background-position: -208px -96px;
}

.ui-icon-bookmark {
  background-position: -224px -96px;
}

.ui-icon-tag {
  background-position: -240px -96px;
}

.ui-icon-home {
  background-position: 0 -112px;
}

.ui-icon-flag {
  background-position: -16px -112px;
}

.ui-icon-calendar {
  background-position: -32px -112px;
}

.ui-icon-cart {
  background-position: -48px -112px;
}

.ui-icon-pencil {
  background-position: -64px -112px;
}

.ui-icon-clock {
  background-position: -80px -112px;
}

.ui-icon-disk {
  background-position: -96px -112px;
}

.ui-icon-calculator {
  background-position: -112px -112px;
}

.ui-icon-zoomin {
  background-position: -128px -112px;
}

.ui-icon-zoomout {
  background-position: -144px -112px;
}

.ui-icon-search {
  background-position: -160px -112px;
}

.ui-icon-wrench {
  background-position: -176px -112px;
}

.ui-icon-gear {
  background-position: -192px -112px;
}

.ui-icon-heart {
  background-position: -208px -112px;
}

.ui-icon-star {
  background-position: -224px -112px;
}

.ui-icon-link {
  background-position: -240px -112px;
}

.ui-icon-cancel {
  background-position: 0 -128px;
}

.ui-icon-plus {
  background-position: -16px -128px;
}

.ui-icon-plusthick {
  background-position: -32px -128px;
}

.ui-icon-minus {
  background-position: -48px -128px;
}

.ui-icon-minusthick {
  background-position: -64px -128px;
}

.ui-icon-close {
  background-position: -80px -128px;
}

.ui-icon-closethick {
  background-position: -96px -128px;
}

.ui-icon-key {
  background-position: -112px -128px;
}

.ui-icon-lightbulb {
  background-position: -128px -128px;
}

.ui-icon-scissors {
  background-position: -144px -128px;
}

.ui-icon-clipboard {
  background-position: -160px -128px;
}

.ui-icon-copy {
  background-position: -176px -128px;
}

.ui-icon-contact {
  background-position: -192px -128px;
}

.ui-icon-image {
  background-position: -208px -128px;
}

.ui-icon-video {
  background-position: -224px -128px;
}

.ui-icon-script {
  background-position: -240px -128px;
}

.ui-icon-alert {
  background-position: 0 -144px;
}

.ui-icon-info {
  background-position: -16px -144px;
}

.ui-icon-notice {
  background-position: -32px -144px;
}

.ui-icon-help {
  background-position: -48px -144px;
}

.ui-icon-check {
  background-position: -64px -144px;
}

.ui-icon-bullet {
  background-position: -80px -144px;
}

.ui-icon-radio-on {
  background-position: -96px -144px;
}

.ui-icon-radio-off {
  background-position: -112px -144px;
}

.ui-icon-pin-w {
  background-position: -128px -144px;
}

.ui-icon-pin-s {
  background-position: -144px -144px;
}

.ui-icon-play {
  background-position: 0 -160px;
}

.ui-icon-pause {
  background-position: -16px -160px;
}

.ui-icon-seek-next {
  background-position: -32px -160px;
}

.ui-icon-seek-prev {
  background-position: -48px -160px;
}

.ui-icon-seek-end {
  background-position: -64px -160px;
}

.ui-icon-seek-start {
  background-position: -80px -160px;
}

.ui-icon-seek-first {
  background-position: -80px -160px;
}

.ui-icon-stop {
  background-position: -96px -160px;
}

.ui-icon-eject {
  background-position: -112px -160px;
}

.ui-icon-volume-off {
  background-position: -128px -160px;
}

.ui-icon-volume-on {
  background-position: -144px -160px;
}

.ui-icon-power {
  background-position: 0 -176px;
}

.ui-icon-signal-diag {
  background-position: -16px -176px;
}

.ui-icon-signal {
  background-position: -32px -176px;
}

.ui-icon-battery-0 {
  background-position: -48px -176px;
}

.ui-icon-battery-1 {
  background-position: -64px -176px;
}

.ui-icon-battery-2 {
  background-position: -80px -176px;
}

.ui-icon-battery-3 {
  background-position: -96px -176px;
}

.ui-icon-circle-plus {
  background-position: 0 -192px;
}

.ui-icon-circle-minus {
  background-position: -16px -192px;
}

.ui-icon-circle-close {
  background-position: -32px -192px;
}

.ui-icon-circle-triangle-e {
  background-position: -48px -192px;
}

.ui-icon-circle-triangle-s {
  background-position: -64px -192px;
}

.ui-icon-circle-triangle-w {
  background-position: -80px -192px;
}

.ui-icon-circle-triangle-n {
  background-position: -96px -192px;
}

.ui-icon-circle-arrow-e {
  background-position: -112px -192px;
}

.ui-icon-circle-arrow-s {
  background-position: -128px -192px;
}

.ui-icon-circle-arrow-w {
  background-position: -144px -192px;
}

.ui-icon-circle-arrow-n {
  background-position: -160px -192px;
}

.ui-icon-circle-zoomin {
  background-position: -176px -192px;
}

.ui-icon-circle-zoomout {
  background-position: -192px -192px;
}

.ui-icon-circle-check {
  background-position: -208px -192px;
}

.ui-icon-circlesmall-plus {
  background-position: 0 -208px;
}

.ui-icon-circlesmall-minus {
  background-position: -16px -208px;
}

.ui-icon-circlesmall-close {
  background-position: -32px -208px;
}

.ui-icon-squaresmall-plus {
  background-position: -48px -208px;
}

.ui-icon-squaresmall-minus {
  background-position: -64px -208px;
}

.ui-icon-squaresmall-close {
  background-position: -80px -208px;
}

.ui-icon-grip-dotted-vertical {
  background-position: 0 -224px;
}

.ui-icon-grip-dotted-horizontal {
  background-position: -16px -224px;
}

.ui-icon-grip-solid-vertical {
  background-position: -32px -224px;
}

.ui-icon-grip-solid-horizontal {
  background-position: -48px -224px;
}

.ui-icon-gripsmall-diagonal-se {
  background-position: -64px -224px;
}

.ui-icon-grip-diagonal-se {
  background-position: -80px -224px;
}

.ui-corner-all,
.ui-corner-top,
.ui-corner-left,
.ui-corner-tl {
  border-top-left-radius: 4px;
}

.ui-corner-all,
.ui-corner-top,
.ui-corner-right,
.ui-corner-tr {
  border-top-right-radius: 4px;
}

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-left,
.ui-corner-bl {
  border-bottom-left-radius: 4px;
}

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-right,
.ui-corner-br {
  border-bottom-right-radius: 4px;
}

.ui-widget-overlay {
  opacity: .5;
  background: #666 url('images/ui-bg_diagonals-thick_20_666666_40x40.png') 50% 50% repeat;
  filter: Alpha(Opacity=50);
}

.ui-widget-shadow {
  margin: -5px 0 0 -5px;
  padding: 5px;
  opacity: .2;
  border-radius: 5px;
  background: #000 url('images/ui-bg_flat_10_000000_40x100.png') 50% 50% repeat-x;
  filter: Alpha(Opacity=20);
}

/* Preload images */
body:after {
  display: none;
  content: url(../assets/img/lightbox/close.png) url(../assets/img/lightbox/loading.gif) url(../assets/img/lightbox/prev.png) url(../assets/img/lightbox/next.png);
}

.lightboxOverlay {
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
  display: none;
  opacity: .8;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
}

.lightbox {
  font-weight: normal;
  line-height: 0;
  position: absolute;
  z-index: 10000;
  left: 0;
  width: 100%;
  text-align: center;
}

.lightbox .lb-image {
  display: block;
  max-width: inherit;
  height: auto;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.lightbox a img {
  border: none;
}

.lb-outerContainer {
  position: relative;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-color: white;
  *zoom: 1;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.lb-outerContainer:after {
  display: table;
  clear: both;
  content: '';
}

.lb-container {
  padding: 4px;
}

.lb-loader {
  line-height: 0;
  position: absolute;
  top: 43%;
  left: 0;
  width: 100%;
  height: 25%;
  text-align: center;
}

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(../assets/img/lightbox/loading.gif) no-repeat;
}

.lb-nav {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.lb-container > .nav {
  left: 0;
}

.lb-nav a {
  outline: none;
  background-image: url('data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==');
}

.lb-prev,
.lb-next {
  display: block;
  height: 100%;
  cursor: pointer;
}

.lb-nav a.lb-prev {
  left: 0;
  float: left;
  width: 34%;
  -webkit-transition: opacity .6s;
  -moz-transition: opacity .6s;
  -o-transition: opacity .6s;
  transition: opacity .6s;
  opacity: 0;
  background: url(../assets/img/lightbox/prev.png) left 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
}

.lb-nav a.lb-prev:hover {
  opacity: 1;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
}

.lb-nav a.lb-next {
  right: 0;
  float: right;
  width: 64%;
  -webkit-transition: opacity .6s;
  -moz-transition: opacity .6s;
  -o-transition: opacity .6s;
  transition: opacity .6s;
  opacity: 0;
  background: url(../assets/img/lightbox/next.png) right 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
}

.lb-nav a.lb-next:hover {
  opacity: 1;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
}

.lb-dataContainer {
  width: 100%;
  margin: 0 auto;
  padding-top: 5px;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
  *zoom: 1;
}

.lb-dataContainer:after {
  display: table;
  clear: both;
  content: '';
}

.lb-data {
  padding: 0 4px;
  color: #ccc;
}

.lb-data .lb-details {
  line-height: 1.1em;
  float: left;
  width: 85%;
  text-align: left;
}

.lb-data .lb-caption {
  font-size: 13px;
  font-weight: bold;
  line-height: 1em;
}

.lb-data .lb-number {
  font-size: 12px;
  display: block;
  clear: left;
  padding-bottom: 1em;
  color: #999;
}

.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  -webkit-transition: opacity .2s;
  -moz-transition: opacity .2s;
  -o-transition: opacity .2s;
  transition: opacity .2s;
  text-align: right;
  opacity: .7;
  outline: none;
  background: url(../assets/img/lightbox/close.png) top right no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
}

.lb-data .lb-close:hover {
  cursor: pointer;
  opacity: 1;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
}

/**** GLOBAL STYLES ****/
body {
  font-family: 'Raleway','Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-sub-title;
  color: #777;
  -webkit-font-smoothing: antialiased;
}

ul {
  margin: 0;
  list-style: none;
}

hr {
  margin: 33px 0;
  border-color: #e1e1e1;
}

.background-highlight {
  background: #f5f5f5;
}

a:focus {
  color: #ddd;
}

.button:hover {
  color: #fff;
}

.main-container {
  position: relative;
  overflow: hidden;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  transition: all .5s ease;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
}

/* LIFE EASIER-ERERS */
.offix {
  overflow: hidden;
}

.block {
  display: block;
}

.left-align {
  left: 0;
}

.right-align {
  right: 0;
}

/***** TYPOGRAPHY *****/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Nixie One','Raleway','Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-sub-title;
  font-weight: 300;
  text-transform: uppercase;
  color: #333;
}

h3 {
  font-size: 30px;
  margin-bottom: 33px;
}

p {
  font-size: 14px;
  line-height: 26px;
}

p:last-child {
  margin-bottom: 0;
}

p.lead {
  font-size: 26px;
  line-height: 35px;
  margin-bottom: 22px;
  color: #333;
}

.sub-title {
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #333;
}

.text-white {
  color: #fff;
}

.icon-large {
  font-size: 33px;
  display: inline-block;
  margin-bottom: 22px;
}

a.large-link {
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 7px;
  padding-bottom: 4px;
  letter-spacing: 3px;
  text-transform: uppercase;
  border-bottom: 1px solid;
}

/* TITLE STYLES */
.page-title {
  margin-bottom: 66px;
}

.title-style-1 span {
  font-size: 22px;
  text-transform: uppercase;
  color: #333;
}

.title-style-1 .span-holder {
  position: relative;
  z-index: 2;
  display: inline-block;
  padding: 11px;
  border: 2px solid;
  background: #fff;
}

.title-style-1 h3 {
  line-height: 1;
  margin: 0;
}

/***** COLORS *****/
.background-dark-grey {
  background: #333;
}

.background-mid-grey {
  background: #f2f2f2;
}

/***** SPACING *****/
section {
  position: relative;
  z-index: 2;
  padding-top: 99px;
  padding-bottom: 111px;
  background: #fff;
}

.pad-small {
  padding-top: 66px;
  padding-bottom: 66px;
}

.pad-large {
  padding: 250px 0 !important;
}

.pad-small-bottom {
  padding-bottom: 77px;
}

.push-small {
  margin-bottom: 11px;
}

.push-large {
  margin-bottom: 44px;
}

.no-left-pad {
  padding-left: 0 !important;
}

.no-right-pad {
  padding-right: 0 !important;
}

/***** BUTTONS *****/
.button {
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  display: inline-block;
  margin-top: 22px;
  padding: 13px 37px 14px 37px;
  -webkit-user-select: auto !important;
  text-transform: uppercase;
  color: #fff;
  border-radius: 25px;
  background: #444;
}

/***** NAVIGATION *****/
.nav-overlay {
  position: absolute;
  z-index: 20;
  top: 0;
  width: 100%;
}

.nav-overlay .nav-toggle {
  display: none;
}

.nav-toggle {
  font-size: 46px;
  cursor: pointer;
  color: #fff;
}

nav {
  -webkit-transition: top .5s ease;
  -moz-transition: top .5s ease;
  transition: top .5s ease;
}

nav.logo-center {
  padding-top: 44px;
}

nav .menu {
  display: inline-block;
  overflow: hidden;
}

nav .menu li {
  float: left;
  padding-bottom: 44px;
  padding-left: 33px;
}

nav .text-left .menu li {
  padding-right: 33px;
  padding-left: 0;
}

nav .menu li a {
  font-size: 11px;
  font-weight: bold;
  display: inline-block;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #fff;
}

nav .logo {
  position: relative;
  bottom: 15px;
}

nav .logo-dark {
  display: none;
}

nav .logo.logo-round {
  bottom: 20px;
}

.fixed-nav {
  position: fixed;
  top: 0 !important;
  padding-top: 22px !important;
  background: rgba(255, 255, 255, .9);
}

.fixed-nav .logo {
  bottom: 5px;
  display: none;
  width: 80px;
}

.fixed-nav .logo-dark {
  display: inline-block !important;
}

.fixed-nav .menu li {
  padding-bottom: 22px;
}

.fixed-nav .menu li a {
  color: #333;
}

nav.top-bar {
  padding-top: 33px;
  background: #fff;
}

.top-bar .logo {
  bottom: 0;
  max-width: 140px;
  margin-bottom: 22px;
}

.top-bar .menu li {
  padding-bottom: 11px !important;
}

.top-bar .menu li a {
  padding-bottom: 15px;
  color: #666;
}

nav.off-screen {
  position: fixed;
  z-index: 9;
  top: 0;
  right: -300px;
  width: 301px !important;
  height: 100%;
  padding: 66px 44px;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  transition: all .5s ease;
  -webkit-transform: translate3d(0, 0, 0);
  text-align: center;
  background: #333;
  -webkit-backface-visibility: hidden;
}

nav.off-screen .logo {
  max-width: 100px;
}

nav.off-screen .line {
  width: 40px;
  height: 1px;
  margin: 22px auto 0 auto;
  background: #999;
}

nav.off-screen .menu li {
  float: none;
  margin-bottom: 11px;
  margin-left: 0;
  padding: 0;
}

nav.off-screen .menu li a {
  padding-bottom: 0;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  transition: all .3s ease;
  color: #999;
}

nav.off-screen .menu li a:hover {
  color: #fff;
}

nav.off-screen .nav-toggle {
  font-size: 46px;
  position: absolute;
  top: 50px;
  right: 350px;
  cursor: pointer;
  color: #fff;
}

nav.off-screen .bottom-content {
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  bottom: 44px;
  left: 0;
  width: 100%;
  letter-spacing: 2px;
  text-transform: uppercase;
}

nav.off-screen .bottom-content a {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  transition: all .3s ease;
  color: #999;
}

nav.off-screen .bottom-content a:hover {
  color: #fff;
}

nav.off-screen .bottom-content i {
  font-size: 16px;
  display: inline-block;
  margin-right: 11px;
}

nav.off-screen .bottom-content ul {
  margin-top: 22px;
}

nav.off-screen .bottom-content ul li {
  font-size: 11px;
  margin-bottom: 6px;
}

.reveal-nav {
  -webkit-transform: translate3d(-300px, 0, 0) !important;
  -moz-transform: translate3d(-300px, 0, 0) !important;
  transform: translate3d(-300px, 0, 0) !important;
}

.move-content {
  -webkit-transform: translate3d(-300px, 0, 0) !important;
  -moz-transform: translate3d(-300px, 0, 0) !important;
  transform: translate3d(-300px, 0, 0) !important;
}

.logo-center .nav-toggle {
  position: absolute;
  top: 11px;
  right: 11px;
  color: #333;
}

/***** SLIDERS & DIVIDERS *****/
.hero-slider {
  position: relative;
  z-index: 1;
  display: block;
  overflow: hidden;
  padding: 0;
}

.flex-fix {
  z-index: 100000 !important;
}

.overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: .3;
}

.hero-slider .slides li {
  position: relative;
  height: 800px;
  background-size: cover !important;
}

.slides li .row {
  position: relative;
  z-index: 2;
}

.gallery-slider {
  padding: 0;
}

.gallery-slider .slides li {
  height: 550px;
  background-size: cover !important;
}

.divider-background {
  overflow: hidden;
  padding: 150px 0;
  background-size: cover !important;
}

.divider-background .row {
  position: relative;
  z-index: 2;
}

.background-image-holder {
  position: absolute;
  z-index: 0;
  top: -20%;
  width: 100%;
  height: 160%;
  background-size: cover !important;
}

.hero-slider .background-image-holder {
  top: 0;
  height: 102%;
}

/* Slider Headline Styles */
.headline-style-1 {
  display: inline-block;
}

.headline-style-1 h1 {
  font-family: 'Kristi','Nixie One','Raleway','Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-sub-title;
  font-size: 120px;
  line-height: 1;
  text-transform: lowercase;
}

.headline-style-1 .sub-title {
  font-size: 13px;
  display: inline-block;
  margin-right: -7px;
  letter-spacing: 7px;
}

.headline-style-1 img {
  display: inline-block;
  margin-bottom: 22px;
}

.headline-style-2 {
  display: inline-block;
}

.headline-style-2 h3 {
  font-family: 'Kristi','Nixie One','Raleway','Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-sub-title;
  font-size: 110px;
  line-height: 130px;
  margin-bottom: 0;
  text-transform: lowercase;
}

.headline-style-2 .sub-title {
  margin-right: -8px;
  letter-spacing: 8px;
}

.headline-style-2 .line {
  width: 80px;
  height: 2px;
  margin: 0 auto;
  margin-top: 34px;
  background: #fff;
}

/***** TEXT SECTION *****/
.no-pad {
  overflow: hidden;
  padding: 0;
}

.photo-container {
  position: absolute;
  top: 0;
  height: 100%;
}

.no-pad .columns {
  padding: 88px 0 99px 0;
}

.no-pad p.columns {
  padding: 0 15px;
}

.no-pad .columns hr {
  border-color: #aaa;
}

/***** MENU *****/
.menu .columns:last-child {
  float: left !important;
}

.menu-title {
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 44px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #333;
}

.menu-item {
  margin-bottom: 44px;
}

.menu-item .title,
.menu-item .price {
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #333;
}

.menu-item .title {
  display: inline-block;
  margin-bottom: 17px;
}

.menu-item .price {
  float: right;
}

.menu-item p {
  line-height: 24px;
  position: relative;
}

.menu-dark {
  background: #333;
}

.menu-dark .title-style-1 {
  border-color: #fff;
}

.menu-dark .title-style-1 span {
  color: #fff;
}

.menu-dark .sub-title {
  color: #fff;
}

.menu-dark .menu-item .title,
.menu-dark .menu-item .price {
  color: #fff;
}

.menu-dark .line {
  background: #fff;
}

.menu-dark .span-holder {
  background: #333;
}

.menu-dark p {
  color: #fff;
}

.menu-dark .medium-4.columns {
  padding: 0 30px;
}

.menu-dark .dietary {
  background: #666;
}

.menu-3 .columns:last-child {
  float: none !important;
}

.menu-filters {
  display: inline-block;
  overflow: hidden;
  margin-bottom: 44px;
}

.menu-filters li {
  font-size: 13px;
  font-weight: bold;
  float: left;
  margin-right: 33px;
  padding: 3px 0 3px 3px;
  cursor: pointer;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #333;
}

.menu-filters li:last-child {
  margin-right: 0;
}

.menu-filters li.active {
  border-bottom: 1px solid #333;
}

.narrow-menu {
  display: none;
  overflow: hidden;
  width: 100%;
  text-align: left;
}

.narrow-menu.active {
  display: block;
}

.narrow-menu .menu-item {
  float: left;
  width: 48%;
  padding-right: 2%;
}

.narrow-menu .menu-item:nth-child(even) {
  padding-right: 0;
  padding-left: 2%;
}

.dietary {
  font-size: 10px;
  line-height: 20px;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-bottom: -20px;
  padding-left: 6px;
  color: #333;
  border-radius: 50%;
  background: #eee;
}

/***** IMAGE GRIDS *****/
.image-grid {
  overflow: hidden;
}

.image-grid a {
  display: inline-block;
  float: left;
  overflow: hidden;
  width: 48%;
  margin: 1% 1%;
}

/***** TESTIMONIALS ******/
.contact .testimonials-slider .slides li {
  padding-right: 44px;
}

.reviews .sub-title {
  display: block;
  letter-spacing: 2px;
}

/**** CONTACT ****/
.contact.divider-background {
  padding: 121px 0;
}

.contact-method {
  margin-bottom: 44px;
}

.contact-method:last-child {
  margin-bottom: 0;
}

.contact-method h6 {
  font-size: 14px;
}

.contact-method p {
  line-height: 26px;
}

.contact-2 .map-holder {
  overflow: hidden;
  width: 100%;
  height: 350px;
}

.contact-2 iframe {
  width: 100%;
  height: 350px;
  border: none;
}

.contact-2 .methods-holder {
  overflow: hidden;
  height: 350px;
  padding: 38px 33px 33px 38px;
  background: rgba(255, 255, 255, .9);
}

.contact-1 .overlay {
  opacity: .7;
}

.map {
  height: 450px;
}

.map iframe {
  width: 100%;
  height: 450px;
  border: none;
}

.map .map-holder .overlay {
  background: none !important;
}

.contact-3 .map-holder {
  position: absolute;
  z-index: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.contact-3 .map-holder .overlay {
  z-index: 1;
  opacity: .8;
}

.contact-3 iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.contact-3 .row {
  position: relative;
  z-index: 2;
}

.contact-3 {
  padding: 121px 0;
}

.contact-3 a i {
  font-size: 33px;
  display: inline-block;
  margin-right: 22px;
  margin-bottom: 44px;
  color: #fff;
}

.contact-3 a.large-link {
  display: inline-block;
  margin-bottom: 11px;
}

.contact-3 a.large-link:hover {
  color: #fff;
}

/***** RESERVATIONS *****/
.reservations h3 {
  margin-bottom: 11px;
}

/***** INSTAFEED *****/
.instagram-feed .row {
  padding-top: 33px;
  padding-bottom: 33px;
}

.instagram-feed span {
  font-size: 12px;
  text-transform: uppercase;
  color: #333;
}

.instagram-feed span i {
  display: inline-block;
  margin: 0 6px;
}

.instagram-feed.no-pad .columns {
  padding-top: 0;
  padding-bottom: 0;
}

.instafeed li {
  float: left;
  width: 12.5%;
}

.instafeed li:nth-child(n+9) {
  display: none;
}

/***** VIDEOS *****/
.divider-video {
  position: relative;
  overflow: hidden;
  padding: 0;
}

.video-wrapper {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
}

.video-wrapper video {
  position: absolute;
  top: 0;
  width: 100%;
}

.divider-video .overlay {
  z-index: 1;
  opacity: .4;
}

.divider-video .divider-content {
  position: relative;
  z-index: 2;
  padding-top: 121px;
  padding-bottom: 121px;
}

/***** OPEN TABLE FORM *****/
.OT_feedTitle {
  display: none;
}

#OT_searchWrapperAll,
#OT_searchWrapper {
  height: auto !important;
  margin-top: 44px !important;
  margin-bottom: 44px !important;
}

#OT_searchWrapper dl {
  font-family: 'Raleway','Helvetica Neue', 'Helvetica', Helvetica, Arial !important;
  position: relative !important;
  width: 100%;
}

#OT_searchWrapper select {
  position: relative;
  bottom: 6px;
  width: 100px;
  height: 30px;
  border: 1px solid #ddd;
  border-radius: 0;
}

#OT_defList {
  position: relative;
  text-align: center;
}

#OT_defList dt,
#OT_defList dd {
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  display: inline-block;
}

#OT_submitWrap {
  position: absolute;
  top: 0;
  display: none !important;
}

#dateDisplay {
  display: none !important;
}

#OT_defList dt,
#OT_defList dd {
  width: auto !important;
  margin-right: 11px;
  margin-left: 0;
}

#OT_searchWrapper #startDate,
#OT_searchWrapper .OT_feedFormfieldCalendar {
  position: relative;
  bottom: 6px;
  width: 100px !important;
  height: 30px;
  padding-left: 6px;
  border: 1px solid #ddd;
  border-radius: 0;
  background: #f8f8f8;
}

#OT_partySizeLbl:before {
  font-family: 'et-line';
  font-size: 24px;
  content: '';
}

#OT_dateLbl:before {
  font-family: 'et-line';
  font-size: 24px;
  margin-right: 6px;
  content: '';
}

#OT_timeLbl:before {
  font-family: 'et-line';
  font-size: 24px;
  content: '';
}

.ui-widget-header {
  font-family: 'Raleway','Helvetica Neue', 'Helvetica', Helvetica, Arial !important;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: none;
  background: #444 !important;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  color: #444;
}

.ui-datepicker-calendar th {
  font-size: 12px;
  text-transform: uppercase;
}

/***** FOOTERS *****/
.footer-container {
  position: relative;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  transition: all .5s ease;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

footer {
  padding: 88px 0;
  background: #333;
}

.footer-text h6 {
  font-family: 'Raleway','Helvetica Neue', 'Helvetica', Helvetica, Arial;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 22px;
  color: #8e8e8e;
}

.footer-text span {
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  color: #727272;
}

.footer-social {
  padding: 44px 0;
}

.social-profiles {
  display: inline-block;
  overflow: hidden;
  margin-bottom: 33px;
  text-align: center;
}

.social-profiles li {
  display: inline-block;
  float: left;
  margin-right: 22px;
}

.social-profiles li:last-child {
  margin-right: 0;
}

.social-profiles li a {
  font-size: 20px;
  display: inline-block;
  width: 40px;
  height: 40px;
  padding-top: 6px;
  text-align: center;
  color: #333;
  border-radius: 50%;
  background: #555;
}

.footer-social .contact-details {
  display: inline-block;
  overflow: hidden;
  margin-bottom: 22px;
}

.footer-social .contact-details li {
  font-size: 12px;
  font-weight: bold;
  float: left;
  margin-right: 22px;
  text-transform: uppercase;
}

.footer-social .contact-details li:after {
  margin-left: 22px;
  content: '|';
}

.footer-social .contact-details li:first-child {
  margin-left: 0;
}

.footer-soaicl .contact-details li:last-child {
  margin-right: 0;
}

.footer-social .contact-details li:last-child:after {
  margin-left: 0;
  content: '';
}

.footer-social .contact-details li a {
  color: #bbb;
}

.footer-social span {
  font-size: 11px;
  font-weight: bold;
  display: inline-block;
  text-transform: uppercase;
}

.footer-contact {
  text-transform: uppercase;
  background: #f2f2f2;
}

.footer-contact p {
  font-size: 12px;
}

.footer-contact .logo-holder {
  position: relative;
  z-index: 1;
  width: 200px;
  margin: 0 auto;
  text-align: center;
  background: #f2f2f2;
}

.footer-contact .logo-holder img {
  width: 120px;
}

.footer-contact hr {
  position: relative;
  z-index: 0;
  bottom: 25px;
  margin: 0;
  border-color: #bbb;
}

.footer-contact .sub-title {
  display: inline-block;
  margin: 33px 0 22px 0;
  letter-spacing: 1px;
}

.footer-contact .social-profiles li a {
  color: #fff;
}

/***** UPDATE 1.2 STYLES *****/
.image-gallery .lightbox-image-holder {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 240px;
  margin-bottom: 30px;
}

/***** Custom style *****/
.menu-item {
  margin-bottom: 30px;
}

.menu-item .title {
  margin-bottom: 0;
}

.menu-item .price {
  text-align: right;
}

.sub-title.custom {
  display: block;
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: solid 1px #333;
}

.menu-item-one-col {
  margin-bottom: 30px;
  padding-right: 4%;
}

.menu-item-one-col .title,
.menu-item-one-col .price {
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #333;
}

.menu-item-one-col .title {
  display: inline-block;
  margin-bottom: 0;
}

.menu-item-one-col .price {
  float: right;
  text-align: right;
}

.menu-item-one-col p {
  line-height: 24px;
  position: relative;
}

.or-separator {
  font-size: 11px;
  font-weight: bold;
  float: right;
  margin-top: 3px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #333;
}

a.reset-link {
  color: #8e8e8e;
}

li.background-paralla {
  background-position: center center !important;
}

.menu-filters li.special-event {
  border-color: #f59500;
}

.menu-filters li.special-event:hover {
  border-bottom: solid 1px #f59500;
}

.menu-filters li.special-event a {
  display: block;
  color: #f59500;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (max-width: 767px) {
  .menu-filters li {
    font-size: 12px;
    width: 100px;
    margin-right: 12px;
    text-align: center;
    letter-spacing: 2px;
  }

  .menu-filters li:last-child {
    line-height: 56px;
    height: 44px;
  }

  nav.off-screen .bottom-content {
    display: block !important;
  }

  .menu-filters li.special-event {
    float: right;
    width: auto;
    margin-bottom: 0;
  }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (max-width: 767px) {
  .nav-toggle {
    display: block !important;
  }

  nav.logo-center {
    overflow: hidden;
    max-height: 70px;
    padding-top: 70px;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    transition: all .5s ease;
    background: rgba(255, 255, 255, .9);
  }

  .logo-center.fixed-nav {
    padding-top: 66px !important;
  }

  .open-nav {
    max-height: 800px !important;
  }

  .logo-center .medium-2 {
    display: none;
  }

  .logo-center .menu {
    width: 100%;
    text-align: left !important;
  }

  .logo-center .menu li {
    float: none;
    padding-right: 0;
    padding-bottom: 11px;
    padding-left: 0;
  }

  .logo-center .menu li a {
    color: #333;
  }

  nav.top-bar .menu {
    text-align: center;
  }

  nav.top-bar .menu li {
    float: none;
    padding-bottom: 0;
    padding-left: 0;
  }

  .video-wrapper {
    display: none;
  }

  p {
    font-size: 12px;
    line-height: 16px;
  }

  h3 {
    margin-bottom: 11px;
  }

  hr {
    margin: 22px 0;
  }

  .headline-style-2 .sub-title {
    margin-right: -6px;
    letter-spacing: 6px;
  }

  .menu-item {
    width: 100% !important;
    padding: 0 !important;
  }

  .narrow-menu .menu-item {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }

  .sub-title {
    line-height: 1.8;
  }

  #instafeed a img {
    width: 33.3333333%;
  }

  #instafeed a:nth-child(n+4) {
    display: none;
  }

  .gallery-slider .slides li {
    height: 250px;
  }

  .no-pad p.columns {
    padding: 0 !important;
  }

  nav.off-screen {
    padding: 33px;
  }

  nav.off-screen .bottom-content {
    display: none;
  }

  nav .push-large {
    display: none;
  }

  nav.off-screen .nav-toggle {
    top: 15px;
    right: 310px;
  }

  .off-screen.reveal-nav .nav-toggle {
    right: 240px !important;
  }

  .divider-background {
    padding: 50px 0;
  }

  .headline-style-1 h1 {
    font-size: 60px;
  }

  .headline-style-1 .sub-title {
    font-size: 10px;
    letter-spacing: 1px;
  }

  .flex-direction-nav {
    display: none;
  }

  .headline-style-2 h3 {
    font-size: 60px;
    line-height: 80px;
  }

  h3 {
    font-size: 22px;
  }

  .no-left-pad {
    padding-left: 15px !important;
  }

  .reviews .columns {
    margin-bottom: 22px;
  }

  .no-pad .columns {
    padding: 88px 15px;
  }
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .no-pad .columns {
    padding-right: 15px;
    padding-left: 15px !important;
  }

  .no-pad .medium-6 {
    width: 75% !important;
  }

  .no-pad .photo-container {
    width: 25% !important;
  }

  .menu .medium-9 {
    width: 100%;
  }

  .menu .menu-item:nth-child(even) {
    float: right;
  }

  .photo-container.left-align {
    display: none;
  }

  .no-pad .medium-6.right {
    width: 100% !important;
  }

  .no-pad p.medium-6 {
    width: 50% !important;
  }

  .reviews .sub-title {
    line-height: 18px;
  }

  #instafeed a img {
    width: 16.666667%;
  }

  #instafeed a:nth-child(n+7) {
    display: none;
  }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .divider-background {
    padding: 80px 0;
  }

  .hero-slider .flex-direction-nav a {
    top: auto;
    bottom: 44px;
  }

  .gallery-slider .slides li {
    height: 400px;
  }

  .no-left-pad {
    padding-right: 15px !important;
  }

  nav.off-screen .bottom-content {
    display: none;
  }

  .video-wrapper {
    display: none;
  }
}
