meta.foundation-version {
  font-family: "/5.2.3/"; }

meta.foundation-mq-small {
  font-family: "/only screen/";
  width: 0em; }

meta.foundation-mq-medium {
  font-family: "/only screen and (min-width:40.063em)/";
  width: 40.063em; }

meta.foundation-mq-large {
  font-family: "/only screen and (min-width:64.063em)/";
  width: 64.063em; }

meta.foundation-mq-xlarge {
  font-family: "/only screen and (min-width:90.063em)/";
  width: 90.063em; }

meta.foundation-mq-xxlarge {
  font-family: "/only screen and (min-width:120.063em)/";
  width: 120.063em; }

meta.foundation-data-attribute-namespace {
  font-family: false; }

html, body {
  height: 100%; }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

html,
body {
  font-size: 100%; }

body {
  background: white;
  color: #222222;
  padding: 0;
  margin: 0;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  position: relative;
  cursor: default; }

a:hover {
  cursor: pointer; }

img {
  max-width: 100%;
  height: auto; }

img {
  -ms-interpolation-mode: bicubic; }

#map_canvas img,
#map_canvas embed,
#map_canvas object,
.map_canvas img,
.map_canvas embed,
.map_canvas object {
  max-width: none !important; }

.left {
  float: left !important; }

.right {
  float: right !important; }

.clearfix:before, .clearfix:after {
  content: " ";
  display: table; }
.clearfix:after {
  clear: both; }

.hide {
  display: none; }

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle; }

textarea {
  height: auto;
  min-height: 50px; }

select {
  width: 100%; }

.row {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
  max-width: 75em; }
  .row:before, .row:after {
    content: " ";
    display: table; }
  .row:after {
    clear: both; }
  .row.collapse > .column,
  .row.collapse > .columns {
    padding-left: 0;
    padding-right: 0; }
  .row.collapse .row {
    margin-left: 0;
    margin-right: 0; }
  .row .row {
    width: auto;
    margin-left: -0.9375em;
    margin-right: -0.9375em;
    margin-top: 0;
    margin-bottom: 0;
    max-width: none; }
    .row .row:before, .row .row:after {
      content: " ";
      display: table; }
    .row .row:after {
      clear: both; }
    .row .row.collapse {
      width: auto;
      margin: 0;
      max-width: none; }
      .row .row.collapse:before, .row .row.collapse:after {
        content: " ";
        display: table; }
      .row .row.collapse:after {
        clear: both; }

.column,
.columns {
  padding-left: 0.9375em;
  padding-right: 0.9375em;
  width: 100%;
  float: left; }

[class*="column"] + [class*="column"]:last-child {
  float: right; }

[class*="column"] + [class*="column"].end {
  float: left; }

@media only screen {
  .small-push-0 {
    position: relative;
    left: 0%;
    right: auto; }

  .small-pull-0 {
    position: relative;
    right: 0%;
    left: auto; }

  .small-push-1 {
    position: relative;
    left: 8.33333%;
    right: auto; }

  .small-pull-1 {
    position: relative;
    right: 8.33333%;
    left: auto; }

  .small-push-2 {
    position: relative;
    left: 16.66667%;
    right: auto; }

  .small-pull-2 {
    position: relative;
    right: 16.66667%;
    left: auto; }

  .small-push-3 {
    position: relative;
    left: 25%;
    right: auto; }

  .small-pull-3 {
    position: relative;
    right: 25%;
    left: auto; }

  .small-push-4 {
    position: relative;
    left: 33.33333%;
    right: auto; }

  .small-pull-4 {
    position: relative;
    right: 33.33333%;
    left: auto; }

  .small-push-5 {
    position: relative;
    left: 41.66667%;
    right: auto; }

  .small-pull-5 {
    position: relative;
    right: 41.66667%;
    left: auto; }

  .small-push-6 {
    position: relative;
    left: 50%;
    right: auto; }

  .small-pull-6 {
    position: relative;
    right: 50%;
    left: auto; }

  .small-push-7 {
    position: relative;
    left: 58.33333%;
    right: auto; }

  .small-pull-7 {
    position: relative;
    right: 58.33333%;
    left: auto; }

  .small-push-8 {
    position: relative;
    left: 66.66667%;
    right: auto; }

  .small-pull-8 {
    position: relative;
    right: 66.66667%;
    left: auto; }

  .small-push-9 {
    position: relative;
    left: 75%;
    right: auto; }

  .small-pull-9 {
    position: relative;
    right: 75%;
    left: auto; }

  .small-push-10 {
    position: relative;
    left: 83.33333%;
    right: auto; }

  .small-pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto; }

  .small-push-11 {
    position: relative;
    left: 91.66667%;
    right: auto; }

  .small-pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto; }

  .column,
  .columns {
    position: relative;
    padding-left: 0.9375em;
    padding-right: 0.9375em;
    float: left; }

  .small-1 {
    width: 8.33333%; }

  .small-2 {
    width: 16.66667%; }

  .small-3 {
    width: 25%; }

  .small-4 {
    width: 33.33333%; }

  .small-5 {
    width: 41.66667%; }

  .small-6 {
    width: 50%; }

  .small-7 {
    width: 58.33333%; }

  .small-8 {
    width: 66.66667%; }

  .small-9 {
    width: 75%; }

  .small-10 {
    width: 83.33333%; }

  .small-11 {
    width: 91.66667%; }

  .small-12 {
    width: 100%; }

  .small-offset-0 {
    margin-left: 0% !important; }

  .small-offset-1 {
    margin-left: 8.33333% !important; }

  .small-offset-2 {
    margin-left: 16.66667% !important; }

  .small-offset-3 {
    margin-left: 25% !important; }

  .small-offset-4 {
    margin-left: 33.33333% !important; }

  .small-offset-5 {
    margin-left: 41.66667% !important; }

  .small-offset-6 {
    margin-left: 50% !important; }

  .small-offset-7 {
    margin-left: 58.33333% !important; }

  .small-offset-8 {
    margin-left: 66.66667% !important; }

  .small-offset-9 {
    margin-left: 75% !important; }

  .small-offset-10 {
    margin-left: 83.33333% !important; }

  .small-offset-11 {
    margin-left: 91.66667% !important; }

  .small-reset-order {
    margin-left: 0;
    margin-right: 0;
    left: auto;
    right: auto;
    float: left; }

  .column.small-centered,
  .columns.small-centered {
    margin-left: auto;
    margin-right: auto;
    float: none; }

  .column.small-uncentered,
  .columns.small-uncentered {
    margin-left: 0;
    margin-right: 0;
    float: left; }

  .column.small-centered:last-child,
  .columns.small-centered:last-child {
    float: none; }

  .column.small-uncentered:last-child,
  .columns.small-uncentered:last-child {
    float: left; }

  .column.small-uncentered.opposite,
  .columns.small-uncentered.opposite {
    float: right; } }
@media only screen and (min-width: 40.063em) {
  .medium-push-0 {
    position: relative;
    left: 0%;
    right: auto; }

  .medium-pull-0 {
    position: relative;
    right: 0%;
    left: auto; }

  .medium-push-1 {
    position: relative;
    left: 8.33333%;
    right: auto; }

  .medium-pull-1 {
    position: relative;
    right: 8.33333%;
    left: auto; }

  .medium-push-2 {
    position: relative;
    left: 16.66667%;
    right: auto; }

  .medium-pull-2 {
    position: relative;
    right: 16.66667%;
    left: auto; }

  .medium-push-3 {
    position: relative;
    left: 25%;
    right: auto; }

  .medium-pull-3 {
    position: relative;
    right: 25%;
    left: auto; }

  .medium-push-4 {
    position: relative;
    left: 33.33333%;
    right: auto; }

  .medium-pull-4 {
    position: relative;
    right: 33.33333%;
    left: auto; }

  .medium-push-5 {
    position: relative;
    left: 41.66667%;
    right: auto; }

  .medium-pull-5 {
    position: relative;
    right: 41.66667%;
    left: auto; }

  .medium-push-6 {
    position: relative;
    left: 50%;
    right: auto; }

  .medium-pull-6 {
    position: relative;
    right: 50%;
    left: auto; }

  .medium-push-7 {
    position: relative;
    left: 58.33333%;
    right: auto; }

  .medium-pull-7 {
    position: relative;
    right: 58.33333%;
    left: auto; }

  .medium-push-8 {
    position: relative;
    left: 66.66667%;
    right: auto; }

  .medium-pull-8 {
    position: relative;
    right: 66.66667%;
    left: auto; }

  .medium-push-9 {
    position: relative;
    left: 75%;
    right: auto; }

  .medium-pull-9 {
    position: relative;
    right: 75%;
    left: auto; }

  .medium-push-10 {
    position: relative;
    left: 83.33333%;
    right: auto; }

  .medium-pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto; }

  .medium-push-11 {
    position: relative;
    left: 91.66667%;
    right: auto; }

  .medium-pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto; }

  .column,
  .columns {
    position: relative;
    padding-left: 0.9375em;
    padding-right: 0.9375em;
    float: left; }

  .medium-1 {
    width: 8.33333%; }

  .medium-2 {
    width: 16.66667%; }

  .medium-3 {
    width: 25%; }

  .medium-4 {
    width: 33.33333%; }

  .medium-5 {
    width: 41.66667%; }

  .medium-6 {
    width: 50%; }

  .medium-7 {
    width: 58.33333%; }

  .medium-8 {
    width: 66.66667%; }

  .medium-9 {
    width: 75%; }

  .medium-10 {
    width: 83.33333%; }

  .medium-11 {
    width: 91.66667%; }

  .medium-12 {
    width: 100%; }

  .medium-offset-0 {
    margin-left: 0% !important; }

  .medium-offset-1 {
    margin-left: 8.33333% !important; }

  .medium-offset-2 {
    margin-left: 16.66667% !important; }

  .medium-offset-3 {
    margin-left: 25% !important; }

  .medium-offset-4 {
    margin-left: 33.33333% !important; }

  .medium-offset-5 {
    margin-left: 41.66667% !important; }

  .medium-offset-6 {
    margin-left: 50% !important; }

  .medium-offset-7 {
    margin-left: 58.33333% !important; }

  .medium-offset-8 {
    margin-left: 66.66667% !important; }

  .medium-offset-9 {
    margin-left: 75% !important; }

  .medium-offset-10 {
    margin-left: 83.33333% !important; }

  .medium-offset-11 {
    margin-left: 91.66667% !important; }

  .medium-reset-order {
    margin-left: 0;
    margin-right: 0;
    left: auto;
    right: auto;
    float: left; }

  .column.medium-centered,
  .columns.medium-centered {
    margin-left: auto;
    margin-right: auto;
    float: none; }

  .column.medium-uncentered,
  .columns.medium-uncentered {
    margin-left: 0;
    margin-right: 0;
    float: left; }

  .column.medium-centered:last-child,
  .columns.medium-centered:last-child {
    float: none; }

  .column.medium-uncentered:last-child,
  .columns.medium-uncentered:last-child {
    float: left; }

  .column.medium-uncentered.opposite,
  .columns.medium-uncentered.opposite {
    float: right; }

  .push-0 {
    position: relative;
    left: 0%;
    right: auto; }

  .pull-0 {
    position: relative;
    right: 0%;
    left: auto; }

  .push-1 {
    position: relative;
    left: 8.33333%;
    right: auto; }

  .pull-1 {
    position: relative;
    right: 8.33333%;
    left: auto; }

  .push-2 {
    position: relative;
    left: 16.66667%;
    right: auto; }

  .pull-2 {
    position: relative;
    right: 16.66667%;
    left: auto; }

  .push-3 {
    position: relative;
    left: 25%;
    right: auto; }

  .pull-3 {
    position: relative;
    right: 25%;
    left: auto; }

  .push-4 {
    position: relative;
    left: 33.33333%;
    right: auto; }

  .pull-4 {
    position: relative;
    right: 33.33333%;
    left: auto; }

  .push-5 {
    position: relative;
    left: 41.66667%;
    right: auto; }

  .pull-5 {
    position: relative;
    right: 41.66667%;
    left: auto; }

  .push-6 {
    position: relative;
    left: 50%;
    right: auto; }

  .pull-6 {
    position: relative;
    right: 50%;
    left: auto; }

  .push-7 {
    position: relative;
    left: 58.33333%;
    right: auto; }

  .pull-7 {
    position: relative;
    right: 58.33333%;
    left: auto; }

  .push-8 {
    position: relative;
    left: 66.66667%;
    right: auto; }

  .pull-8 {
    position: relative;
    right: 66.66667%;
    left: auto; }

  .push-9 {
    position: relative;
    left: 75%;
    right: auto; }

  .pull-9 {
    position: relative;
    right: 75%;
    left: auto; }

  .push-10 {
    position: relative;
    left: 83.33333%;
    right: auto; }

  .pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto; }

  .push-11 {
    position: relative;
    left: 91.66667%;
    right: auto; }

  .pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto; } }
@media only screen and (min-width: 64.063em) {
  .large-push-0 {
    position: relative;
    left: 0%;
    right: auto; }

  .large-pull-0 {
    position: relative;
    right: 0%;
    left: auto; }

  .large-push-1 {
    position: relative;
    left: 8.33333%;
    right: auto; }

  .large-pull-1 {
    position: relative;
    right: 8.33333%;
    left: auto; }

  .large-push-2 {
    position: relative;
    left: 16.66667%;
    right: auto; }

  .large-pull-2 {
    position: relative;
    right: 16.66667%;
    left: auto; }

  .large-push-3 {
    position: relative;
    left: 25%;
    right: auto; }

  .large-pull-3 {
    position: relative;
    right: 25%;
    left: auto; }

  .large-push-4 {
    position: relative;
    left: 33.33333%;
    right: auto; }

  .large-pull-4 {
    position: relative;
    right: 33.33333%;
    left: auto; }

  .large-push-5 {
    position: relative;
    left: 41.66667%;
    right: auto; }

  .large-pull-5 {
    position: relative;
    right: 41.66667%;
    left: auto; }

  .large-push-6 {
    position: relative;
    left: 50%;
    right: auto; }

  .large-pull-6 {
    position: relative;
    right: 50%;
    left: auto; }

  .large-push-7 {
    position: relative;
    left: 58.33333%;
    right: auto; }

  .large-pull-7 {
    position: relative;
    right: 58.33333%;
    left: auto; }

  .large-push-8 {
    position: relative;
    left: 66.66667%;
    right: auto; }

  .large-pull-8 {
    position: relative;
    right: 66.66667%;
    left: auto; }

  .large-push-9 {
    position: relative;
    left: 75%;
    right: auto; }

  .large-pull-9 {
    position: relative;
    right: 75%;
    left: auto; }

  .large-push-10 {
    position: relative;
    left: 83.33333%;
    right: auto; }

  .large-pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto; }

  .large-push-11 {
    position: relative;
    left: 91.66667%;
    right: auto; }

  .large-pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto; }

  .column,
  .columns {
    position: relative;
    padding-left: 0.9375em;
    padding-right: 0.9375em;
    float: left; }

  .large-1 {
    width: 8.33333%; }

  .large-2 {
    width: 16.66667%; }

  .large-3 {
    width: 25%; }

  .large-4 {
    width: 33.33333%; }

  .large-5 {
    width: 41.66667%; }

  .large-6 {
    width: 50%; }

  .large-7 {
    width: 58.33333%; }

  .large-8 {
    width: 66.66667%; }

  .large-9 {
    width: 75%; }

  .large-10 {
    width: 83.33333%; }

  .large-11 {
    width: 91.66667%; }

  .large-12 {
    width: 100%; }

  .large-offset-0 {
    margin-left: 0% !important; }

  .large-offset-1 {
    margin-left: 8.33333% !important; }

  .large-offset-2 {
    margin-left: 16.66667% !important; }

  .large-offset-3 {
    margin-left: 25% !important; }

  .large-offset-4 {
    margin-left: 33.33333% !important; }

  .large-offset-5 {
    margin-left: 41.66667% !important; }

  .large-offset-6 {
    margin-left: 50% !important; }

  .large-offset-7 {
    margin-left: 58.33333% !important; }

  .large-offset-8 {
    margin-left: 66.66667% !important; }

  .large-offset-9 {
    margin-left: 75% !important; }

  .large-offset-10 {
    margin-left: 83.33333% !important; }

  .large-offset-11 {
    margin-left: 91.66667% !important; }

  .large-reset-order {
    margin-left: 0;
    margin-right: 0;
    left: auto;
    right: auto;
    float: left; }

  .column.large-centered,
  .columns.large-centered {
    margin-left: auto;
    margin-right: auto;
    float: none; }

  .column.large-uncentered,
  .columns.large-uncentered {
    margin-left: 0;
    margin-right: 0;
    float: left; }

  .column.large-centered:last-child,
  .columns.large-centered:last-child {
    float: none; }

  .column.large-uncentered:last-child,
  .columns.large-uncentered:last-child {
    float: left; }

  .column.large-uncentered.opposite,
  .columns.large-uncentered.opposite {
    float: right; }

  .push-0 {
    position: relative;
    left: 0%;
    right: auto; }

  .pull-0 {
    position: relative;
    right: 0%;
    left: auto; }

  .push-1 {
    position: relative;
    left: 8.33333%;
    right: auto; }

  .pull-1 {
    position: relative;
    right: 8.33333%;
    left: auto; }

  .push-2 {
    position: relative;
    left: 16.66667%;
    right: auto; }

  .pull-2 {
    position: relative;
    right: 16.66667%;
    left: auto; }

  .push-3 {
    position: relative;
    left: 25%;
    right: auto; }

  .pull-3 {
    position: relative;
    right: 25%;
    left: auto; }

  .push-4 {
    position: relative;
    left: 33.33333%;
    right: auto; }

  .pull-4 {
    position: relative;
    right: 33.33333%;
    left: auto; }

  .push-5 {
    position: relative;
    left: 41.66667%;
    right: auto; }

  .pull-5 {
    position: relative;
    right: 41.66667%;
    left: auto; }

  .push-6 {
    position: relative;
    left: 50%;
    right: auto; }

  .pull-6 {
    position: relative;
    right: 50%;
    left: auto; }

  .push-7 {
    position: relative;
    left: 58.33333%;
    right: auto; }

  .pull-7 {
    position: relative;
    right: 58.33333%;
    left: auto; }

  .push-8 {
    position: relative;
    left: 66.66667%;
    right: auto; }

  .pull-8 {
    position: relative;
    right: 66.66667%;
    left: auto; }

  .push-9 {
    position: relative;
    left: 75%;
    right: auto; }

  .pull-9 {
    position: relative;
    right: 75%;
    left: auto; }

  .push-10 {
    position: relative;
    left: 83.33333%;
    right: auto; }

  .pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto; }

  .push-11 {
    position: relative;
    left: 91.66667%;
    right: auto; }

  .pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto; } }
.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-justify {
  text-align: justify !important; }

@media only screen and (max-width: 40em) {
  .small-only-text-left {
    text-align: left !important; }

  .small-only-text-right {
    text-align: right !important; }

  .small-only-text-center {
    text-align: center !important; }

  .small-only-text-justify {
    text-align: justify !important; } }
@media only screen {
  .small-text-left {
    text-align: left !important; }

  .small-text-right {
    text-align: right !important; }

  .small-text-center {
    text-align: center !important; }

  .small-text-justify {
    text-align: justify !important; } }
@media only screen and (min-width: 40.063em) and (max-width: 64em) {
  .medium-only-text-left {
    text-align: left !important; }

  .medium-only-text-right {
    text-align: right !important; }

  .medium-only-text-center {
    text-align: center !important; }

  .medium-only-text-justify {
    text-align: justify !important; } }
@media only screen and (min-width: 40.063em) {
  .medium-text-left {
    text-align: left !important; }

  .medium-text-right {
    text-align: right !important; }

  .medium-text-center {
    text-align: center !important; }

  .medium-text-justify {
    text-align: justify !important; } }
@media only screen and (min-width: 64.063em) and (max-width: 90em) {
  .large-only-text-left {
    text-align: left !important; }

  .large-only-text-right {
    text-align: right !important; }

  .large-only-text-center {
    text-align: center !important; }

  .large-only-text-justify {
    text-align: justify !important; } }
@media only screen and (min-width: 64.063em) {
  .large-text-left {
    text-align: left !important; }

  .large-text-right {
    text-align: right !important; }

  .large-text-center {
    text-align: center !important; }

  .large-text-justify {
    text-align: justify !important; } }
@media only screen and (min-width: 90.063em) and (max-width: 120em) {
  .xlarge-only-text-left {
    text-align: left !important; }

  .xlarge-only-text-right {
    text-align: right !important; }

  .xlarge-only-text-center {
    text-align: center !important; }

  .xlarge-only-text-justify {
    text-align: justify !important; } }
@media only screen and (min-width: 90.063em) {
  .xlarge-text-left {
    text-align: left !important; }

  .xlarge-text-right {
    text-align: right !important; }

  .xlarge-text-center {
    text-align: center !important; }

  .xlarge-text-justify {
    text-align: justify !important; } }
@media only screen and (min-width: 120.063em) and (max-width: 99999999em) {
  .xxlarge-only-text-left {
    text-align: left !important; }

  .xxlarge-only-text-right {
    text-align: right !important; }

  .xxlarge-only-text-center {
    text-align: center !important; }

  .xxlarge-only-text-justify {
    text-align: justify !important; } }
@media only screen and (min-width: 120.063em) {
  .xxlarge-text-left {
    text-align: left !important; }

  .xxlarge-text-right {
    text-align: right !important; }

  .xxlarge-text-center {
    text-align: center !important; }

  .xxlarge-text-justify {
    text-align: justify !important; } }
/* Typography resets */
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

/* Default Link Styles */
a {
  color: #2ba6cb;
  text-decoration: none;
  line-height: inherit; }
  a:hover, a:focus {
    color: #258faf; }
  a img {
    border: none; }

/* Default paragraph styles */
p {
  font-family: inherit;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1.25rem;
  text-rendering: optimizeLegibility; }
  p.lead {
    font-size: 1.21875rem;
    line-height: 1.6; }
  p aside {
    font-size: 0.875rem;
    line-height: 1.35;
    font-style: italic; }

/* Default header styles */
h1, h2, h3, h4, h5, h6 {
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #222222;
  text-rendering: optimizeLegibility;
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  line-height: 1.4; }
  h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
    font-size: 60%;
    color: #6f6f6f;
    line-height: 0; }

h1 {
  font-size: 2.125rem; }

h2 {
  font-size: 1.6875rem; }

h3 {
  font-size: 1.375rem; }

h4 {
  font-size: 1.125rem; }

h5 {
  font-size: 1.125rem; }

h6 {
  font-size: 1rem; }

.subheader {
  line-height: 1.4;
  color: #6f6f6f;
  font-weight: normal;
  margin-top: 0.2rem;
  margin-bottom: 0.5rem; }

hr {
  border: solid #dddddd;
  border-width: 1px 0 0;
  clear: both;
  margin: 1.25rem 0 1.1875rem;
  height: 0; }

/* Helpful Typography Defaults */
em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 60%;
  line-height: inherit; }

code {
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: bold;
  color: #910b0e; }

/* Lists */
ul,
ol,
dl {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1.25rem;
  list-style-position: outside;
  font-family: inherit; }

ul {
  margin-left: 1.1rem; }
  ul.no-bullet {
    margin-left: 0; }
    ul.no-bullet li ul,
    ul.no-bullet li ol {
      margin-left: 1.25rem;
      margin-bottom: 0;
      list-style: none; }

/* Unordered Lists */
ul li ul,
ul li ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }
ul.square li ul, ul.circle li ul, ul.disc li ul {
  list-style: inherit; }
ul.square {
  list-style-type: square;
  margin-left: 1.1rem; }
ul.circle {
  list-style-type: circle;
  margin-left: 1.1rem; }
ul.disc {
  list-style-type: disc;
  margin-left: 1.1rem; }
ul.no-bullet {
  list-style: none; }

/* Ordered Lists */
ol {
  margin-left: 1.4rem; }
  ol li ul,
  ol li ol {
    margin-left: 1.25rem;
    margin-bottom: 0; }

/* Definition Lists */
dl dt {
  margin-bottom: 0.3rem;
  font-weight: bold; }
dl dd {
  margin-bottom: 0.75rem; }

/* Abbreviations */
abbr,
acronym {
  text-transform: uppercase;
  font-size: 90%;
  color: #222222;
  border-bottom: 1px dotted #dddddd;
  cursor: help; }

abbr {
  text-transform: none; }

/* Blockquotes */
blockquote {
  margin: 0 0 1.25rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #dddddd; }
  blockquote cite {
    display: block;
    font-size: 0.8125rem;
    color: #555555; }
    blockquote cite:before {
      content: "\2014 \0020"; }
    blockquote cite a,
    blockquote cite a:visited {
      color: #555555; }

blockquote,
blockquote p {
  line-height: 1.6;
  color: #6f6f6f; }

/* Microformats */
.vcard {
  display: inline-block;
  margin: 0 0 1.25rem 0;
  border: 1px solid #dddddd;
  padding: 0.625rem 0.75rem; }
  .vcard li {
    margin: 0;
    display: block; }
  .vcard .fn {
    font-weight: bold;
    font-size: 0.9375rem; }

.vevent .summary {
  font-weight: bold; }
.vevent abbr {
  cursor: default;
  text-decoration: none;
  font-weight: bold;
  border: none;
  padding: 0 0.0625rem; }

@media only screen and (min-width: 40.063em) {
  h1, h2, h3, h4, h5, h6 {
    line-height: 1.4; }

  h1 {
    font-size: 2.75rem; }

  h2 {
    font-size: 2.3125rem; }

  h3 {
    font-size: 1.6875rem; }

  h4 {
    font-size: 1.4375rem; }

  h5 {
    font-size: 1.125rem; }

  h6 {
    font-size: 1rem; } }
/*
 * Print styles.
 *
 * Inlined to avoid required HTTP connection: www.phpied.com/delay-loading-your-print-css/
 * Credit to Paul Irish and HTML5 Boilerplate (html5boilerplate.com)
*/
.print-only {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    color: black !important;
    /* Black prints faster: h5bp.com/s */
    box-shadow: none !important;
    text-shadow: none !important; }

  a,
  a:visited {
    text-decoration: underline; }

  a[href]:after {
    content: " (" attr(href) ")"; }

  abbr[title]:after {
    content: " (" attr(title) ")"; }

  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: ""; }

  pre,
  blockquote {
    border: 1px solid #999999;
    page-break-inside: avoid; }

  thead {
    display: table-header-group;
    /* h5bp.com/t */ }

  tr,
  img {
    page-break-inside: avoid; }

  img {
    max-width: 100% !important; }

  // @page {
  //   margin: 0.5cm; }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }

  h2,
  h3 {
    page-break-after: avoid; }

  .hide-on-print {
    display: none !important; }

  .print-only {
    display: block !important; }

  .hide-for-print {
    display: none !important; }

  .show-for-print {
    display: inherit !important; } }
